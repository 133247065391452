import React, { useEffect, useState, useRef } from "react";
import classNames from "../../react-utils/classNames";
import { themeScheme } from "utils";
import {
  MenuIcon,
  BellIcon,
  ChevronDownIcon,
  SelectorIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Fragment } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { SunIcon, MoonIcon } from "@heroicons/react/outline";
import DropdownMenu from "../menu";
import theme from "utils/theme";

const JustLink = ({ item, ...props }) => {
  const LinkRenderer = props.LinkRenderer;

  return (
    <LinkRenderer
      href={item.href}
      className="text-sm font-medium text-gray-900 dark:text-white"
    >
      {item.name}
    </LinkRenderer>
  );
};

const ItemOrMenu = (props) => {
  return (
    <>
      {props.items.map((item) =>
        item.children.length ? (
          <DropdownMenu key={item.key} item={item} {...props} />
        ) : (
          <JustLink key={item.key} item={item} {...props} />
        )
      )}
    </>
  );
};

const Topbar = (props: any) => {
  return (
    <div className="hidden lg:min-w-0 lg:flex-1 lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1">
        <div
          className={classNames(
            "pl-6 font-semibold max-w-2xl relative text-gray-600 focus-within:text-gray-500 flex items-center space-x-4"
          )}
        >
          {props.leftCustomItems}
          <ItemOrMenu
            openTo="origin-top-right absolute z-30 left-0"
            items={props.leftNavigation}
            {...props}
          />
        </div>
      </div>
      <div className="ml-10 pr-4 flex-shrink-0 flex items-center space-x-10">
        <div className="flex items-center space-x-4">
          <ItemOrMenu
            openTo="origin-top-right absolute z-30 right-0"
            items={props.rightNavigation}
            {...props}
          />

          {props.rightCustomItems}
        </div>
      </div>
    </div>
  );
};

export default Topbar;
