import { axiosObject } from "../setup";

function teamAdminCanViewTokens(id) {
  return new Promise((resolve, reject) => {
    let uri = `/access/teams/${id}/tokens`;

    axiosObject
      .get(uri)
      .then((response) => {
        // console.log('Called api to fetch teams', response.data);
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function teamsAdminCanCreateTokens(uuid, payload) {
  return new Promise((resolve, reject) => {
    let uri = `/access/teams/${uuid}/tokens`;

    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        console.log("errors", errors.response.data.errors);
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function teamAdminCanViewToken(id, tokenId) {
  return new Promise((resolve, reject) => {
    let uri = `/access/teams/${id}/tokens/${tokenId}`;

    axiosObject
      .get(uri)
      .then((response) => {
        // console.log('Called api to fetch teams', response.data);
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function teamAdminCanUpdateToken(id, tokenId, payload) {
  return new Promise((resolve, reject) => {
    let uri = `/access/teams/${id}/tokens/${tokenId}`;

    axiosObject
      .put(uri, payload)
      .then((response) => {
        // console.log('Called api to fetch teams', response.data);
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function teamAdminCanDeleteToken(id, tokenId) {
  return new Promise((resolve, reject) => {
    let uri = `/access/teams/${id}/tokens/${tokenId}`;

    axiosObject
      .delete(uri)
      .then((response) => {
        // console.log('Called api to fetch teams', response.data);
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

export {
  teamAdminCanViewTokens,
  teamsAdminCanCreateTokens,
  teamAdminCanViewToken,
  teamAdminCanUpdateToken,
  teamAdminCanDeleteToken,
};
