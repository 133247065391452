import { axiosObject } from "../setup";

function usersCanViewEnvironmentUsers(environment, params = {}) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/${environment}/users`;

    params["q"] = params["query"];

    axiosObject
      .get(uri, {
        params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function usersViewUser(environment, uuid) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/${environment}/users/${uuid}?includes=contact_infos`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

export { usersCanViewEnvironmentUsers, usersViewUser };
