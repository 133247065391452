import React from "react";
// import "../tailwind.css";
import classNames from "../react-utils/classNames";

export default function FormHeading({ children, ...props }) {
  const textAndFontStyles =
    props.textAndFontStyles ||
    "text-2xl font-medium text-gray-800 dark:text-white";

  return <h3 className={classNames(textAndFontStyles)}>{children}</h3>;
}
