import React from "react";
import classNames from "../react-utils/classNames";
import { XIcon } from "@heroicons/react/solid";

const Button = function Button({
  type = "button",
  onClick = () => {},
  disable,
  children,
  busy = false,
  busyText = "Processing..",
  ...props
}) {
  const alignmentClasses =
    props.alignmentClasses ||
    "inline-flex justify-center items-center font-normal text-sm focus:outline-none rounded-md shadow-sm";

  const fontClasses = props.fontClasses || "font-sans";

  const colorClasses =
    props.colorClasses || "bg-blue-500 hover:bg-blue-700 text-white";

  const paddingClasses = props.paddingClasses || "px-4 py-2";

  const borderClasses = props.borderClasses || "border border-transparent";

  const widthClasses = props.widthClasses || "";
  const disabledClasses = props.disabledClasses || "disabled:opacity-50";

  return (
    <button
      type={type}
      className={classNames(
        disabledClasses,
        alignmentClasses,
        fontClasses,
        colorClasses,
        paddingClasses,
        borderClasses,
        widthClasses,
        props.additionalClasses
      )}
      onClick={onClick}
      disabled={disable || busy}
    >
      {busy && (
        <>
          {busyText}
          {/* <XIcon className="h-5 w-5" aria-hidden="true" /> */}
        </>
      )}
      {!busy && children}
    </button>
  );
};

Button.defaultProps = {
  type: "button",
  onClick: () => {},
};

export default Button;
