import { createSlice } from "@reduxjs/toolkit";

export interface AlertState {
  message: any;
}

const initialState: AlertState = {
  message: null,
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlertMessage: (state, action) => {
      state.message = action.payload;
    },

    clearAlertMessage: (state, action) => {
      state.message = null;
    },
  },
});

export const { setAlertMessage, clearAlertMessage } = alertSlice.actions;

export default alertSlice.reducer;
