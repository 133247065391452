import "../styles/globals.css";
import "../styles/codemirror.css";
import "../styles/3024-day.css";
import "../styles/3024-night.css";
import type { AppProps } from "next/app";
import React, { useEffect, useState } from "react";
import { fetcher } from "services";
import { Provider } from "react-redux";
import { webStore } from "store";
import { SWRConfig } from "swr";
import { AuthWrapper } from "ui";
import { ThemeProvider } from "next-themes";
import { useRouter } from "next/router";

function MyApp({ Component, pageProps }: AppProps) {
  const [ready, setReady] = useState(process.env.NODE_ENV !== "development");

  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      window.gtag("config", process.env.NEXT_PUBLIC_GA_ID, {
        page_path: url,
      });
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    (async () => {
      if (process.env.NEXT_PUBLIC_MOCK_AUTH === "true") {
        await fetcher("/api/login", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        });
        setReady(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (window.Beacon) {
      window.Beacon("config", {
        display: {
          style: "manual",
          position: "right",
        },
      });
    }
  }, []);

  return (
    <>
      <SWRConfig
        value={{
          fetcher: fetcher,
          onError: (err) => {
            console.error(err);
          },
        }}
      >
        <Provider store={webStore}>
          {ready && (
            <AuthWrapper>
              <ThemeProvider attribute="class" defaultTheme="light">
                <Component {...pageProps} />
              </ThemeProvider>
            </AuthWrapper>
          )}
        </Provider>
      </SWRConfig>
    </>
  );
}

export default MyApp;
