import React, { forwardRef, useState, useEffect } from "react";
import includes from "lodash/includes";
import indexOf from "lodash/indexOf";
import filter from "lodash/filter";
import map from "lodash/map";
import join from "lodash/join";
import eventBus from "../eventBus";
import useOutsideClicker from "../../react-utils/useOutsideClicker";
import Radio from "../../react-pickers/Radio/index";
import classNames from "../../react-utils/classNames";

const SelectFilter = forwardRef(function SelectFilter(
  { onChange, options, selected, ...other },
  ref
) {
  const visRef = useOutsideClicker(() => {
    setIsOn(false);
  });

  const [isOn, setIsOn] = useState(false);
  const [selectedChoices, setSelectedChoices] = useState(selected);
  const [labelText, setLabelText] = useState("");

  const computeLabelText = (choices) => {
    let labels = map(choices, "label");
    if (labels.length) {
      setLabelText(join(labels, ", "));
    } else {
      setLabelText("");
    }
  };

  useEffect(() => {
    computeLabelText(selectedChoices);
  }, [selectedChoices]);

  eventBus.on("clear", () => {
    setSelectedChoices([]);
    computeLabelText([]);
  });

  const clearFilter = () => {
    setSelectedChoices([]);
    computeLabelText([]);
    onChange([]);
  };

  const handleOnChange = (value) => {
    setSelectedChoices([value]);
    computeLabelText([value]);
    onChange([value]);
  };

  return (
    <>
      <div className="relative inline-block" ref={visRef}>
        <div>
          <span className="rounded-md">
            <div
              onClick={() => setIsOn(!isOn)}
              className="inline-flex items-center justify-center w-full text-xs font-medium  uppercase cursor-pointer leading-5  transition ease-in-out duration-150 "
              aria-haspopup="true"
              aria-expanded="true"
            >
              <input
                value={labelText}
                type="text"
                disabled
                className="text-xs px-2 py-2 dark:bg-gray-900 bg-white rounded border border-gray-200 dark:border-gray-700 text-gray-900 dark:text-white focus:ring-blue-400 focus:border-blue-400 outline-none"
              />

              <div
                className={
                  "absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
                }
              >
                <svg
                  className="w-3 h-3 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="#6b7280"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                    clipRule="evenodd"
                  />
                </svg>{" "}
              </div>
            </div>
          </span>
        </div>

        <div
          className={classNames(
            "absolute z-50 left-0 w-40 font-normal border border-gray-200 dark:border-gray-700 origin-top-left rounded-md",
            isOn ? "visible" : "invisible"
          )}
        >
          <div className="dark:bg-gray-900 bg-white rounded-md shadow-xs">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="block px-4 py-2 space-y-4 divide-gray-200 dark:divide-gray-700 divide-y-2">
                <div className="h-32 overflow-y-scroll">
                  <Radio
                    pickerWrapperClasses="mt-0"
                    multiple={true}
                    dataSource={() => options}
                    defaultSelected={selectedChoices}
                    onChange={(v) => {
                      console.log("v", v);
                      handleOnChange(v);
                    }}
                  />
                </div>
                <div className="text-xs pt-2 flex justify-between items-center">
                  <span className="text-gray-500 dark:text-gray-400">
                    {options.length} items
                  </span>
                  <span
                    className="text-xs  capitalize cursor-pointer text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
                    onClick={clearFilter}
                  >
                    Clear
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

SelectFilter.defaultProps = {
  onChange: () => {},
  options: [],
  selected: [],
};

export default SelectFilter;
