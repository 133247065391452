import React from "react";
import Base from "./base";

export default (props) => {
  return (
    <Base
      colorClasses="initial:text-blue-500 initial:hover:text-blue-600 initial:dark:text-blue-400 initial:dark:hover:text-blue-300"
      borderClasses="border-0 shadow-none"
      {...props}
    />
  );
};
