import React from "react";
import Base from "./base";

export default (props) => {
  return (
    <Base
      colorClasses="bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 text-white"
      borderClasses="border border-transparent"
      {...props}
    />
  );
};
