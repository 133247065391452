import React from "react";
import Base from "./base";

export default (props) => {
  return (
    <Base
      colorClasses="text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700"
      borderClasses="border border-gray-300 dark:border-gray-800"
      {...props}
    />
  );
};
