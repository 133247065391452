import cookie from "cookie";
import { removeAccessToken } from "./cookieHelper";
import { withIronSessionApiRoute } from "iron-session/next";
import { sessionOptions } from "./session";

const nextLogout = withIronSessionApiRoute(async (req, res) => {
  try {
    const tokens = cookie.parse(req.headers.cookie);
    const token = tokens.access_token;

    const url = process.env.NEXT_PUBLIC_API_URL + "/access/logout";

    // console.log("nextLogout Called", url, token);

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const data = await response.json();

    // console.log("Logout", response.ok, data);

    let cookieValue = removeAccessToken(req);
    // console.log("cookieValue-1234", cookieValue);
    res.setHeader("Set-Cookie", [cookieValue]);

    req.session.destroy();
    // console.log("nextLogout logout complete");
    res.json({ isLoggedIn: false });
  } catch (error) {
    console.log("Logout Error", error);
  }
}, sessionOptions);

export { nextLogout };
