import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { useDayzed } from "dayzed";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import classNames from "../react-utils/classNames";

const monthNamesShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function RangeDatePicker({ onChange, userTimezone, selected }) {
  const [selectedDates, setSelectedDates] = useState([]);
  const [hoveredDate, setHoveredDate] = useState(null);

  useEffect(() => {
    if (selected.length === 2) {
      let oldDates = selected.map((date) => {
        return new Date(convertWithTimezone(new Date(date)));
      });

      setSelectedDates(oldDates);
    }
  }, []);

  const onMouseLeave = () => {
    setHoveredDate(null);
  };

  const convertWithTimezone = (date) => {
    const datepickerDate = DateTime.fromJSDate(date);

    const newDate = DateTime.fromObject(
      {
        year: datepickerDate.year,
        month: datepickerDate.month,
        day: datepickerDate.day,
      },
      { zone: userTimezone }
    );

    return newDate.toISO();
  };

  useEffect(() => {
    if (selectedDates.length === 2) {
      let newDates = selectedDates.map((date) => {
        return convertWithTimezone(date);
      });

      let oldDates = selected.map((date) => {
        return convertWithTimezone(new Date(date));
      });

      if (oldDates.join("-") !== newDates.join("-")) {
        onChange(newDates);
      }
    }
  }, [selectedDates.length]);

  // Date level
  const onMouseEnter = (date) => {
    // console.log("onMouseEnter", date);
    if (!selectedDates.length) {
      return;
    }
    setHoveredDate(date);
  };

  const handleOnDateSelected = ({ selected, selectable, date }) => {
    if (!selectable) {
      return;
    }
    let dateTime = date.getTime();
    let newDates = [...selectedDates];
    if (selectedDates.length) {
      if (selectedDates.length === 1) {
        let firstTime = selectedDates[0].getTime();
        if (firstTime < dateTime) {
          newDates.push(date);
        } else {
          newDates.unshift(date);
        }
        setSelectedDates(newDates);
      } else if (newDates.length === 2) {
        setSelectedDates([date]);
      }
    } else {
      newDates.push(date);
      setSelectedDates(newDates);
    }
  };

  const isInRange = (date) => {
    let selected = selectedDates;
    if (selected.length) {
      let firstSelected = selected[0].getTime();
      if (selected.length === 2) {
        let secondSelected = selected[1].getTime();
        return firstSelected < date && secondSelected > date;
      } else {
        return (
          hoveredDate &&
          ((firstSelected < date && hoveredDate >= date) ||
            (date < firstSelected && date >= hoveredDate))
        );
      }
    }
    return false;
  };

  let dayzedData = useDayzed({
    date: new Date(),
    minDate: new Date("01/01/2018"),
    maxDate: new Date(),
    selected: selectedDates,
    onDateSelected: handleOnDateSelected,
    showOutsideDays: true,
    monthsToDisplay: 1,
  });

  return (
    <Calendars
      {...dayzedData}
      hoveredDate={hoveredDate}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isInRange={isInRange}
    />
  );
}

const Calendars = ({
  calendars,
  getBackProps,
  getForwardProps,
  getDateProps,
  onMouseEnter,
  onMouseLeave,
  isInRange,
}) => {
  return (
    <div
      className="bg-white dark:bg-gray-900 rounded-lg"
      onMouseLeave={onMouseLeave}
    >
      {calendars.map((calendar) => (
        <div
          className="text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 xl:col-start-9"
          key={`${calendar.month}${calendar.year}`}
        >
          <div className="flex items-center text-gray-800 dark:text-gray-100 py-2 border-b border-t border-gray-200 dark:border-gray-800 ">
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-200 disabled:opacity-50"
              {...getBackProps({ calendars })}
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className="flex-auto font-semibold">
              {monthNamesShort[calendar.month]} {calendar.year}
            </div>
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-200 disabled:opacity-50"
              {...getForwardProps({ calendars })}
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>

          <div className="mt-3 grid grid-cols-7 text-xs leading-6 text-gray-500 dark:text-gray-300 bg-white dark:bg-gray-900">
            <div>S</div>
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
          </div>

          <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 dark:bg-gray-700 text-sm ring-1 ring-gray-200 dark:ring-gray-700">
            {calendar.weeks.map((week, weekIndex) =>
              week.map((dateObj, dayIdx) => {
                let key = `${calendar.month}${calendar.year}${weekIndex}${dayIdx}`;

                let {
                  date,
                  selected,
                  selectable,
                  today,
                  prevMonth,
                  nextMonth,
                } = dateObj;
                let isCurrentMonth = !prevMonth && !nextMonth;
                selectable = selectable && isCurrentMonth;
                let inRange = isInRange(date);

                // console.log("isInRange(date)", date, isInRange(date));

                return (
                  <button
                    type="button"
                    key={key}
                    {...getDateProps({
                      dateObj,
                      onMouseEnter: () => {
                        onMouseEnter(date);
                      },
                    })}
                    className={classNames(
                      "py-1.5 focus:z-10 disabled:opacity-50",
                      selectable
                        ? "initial:bg-white initial:dark:bg-gray-900"
                        : "initial:bg-gray-50 initial:dark:bg-gray-800",
                      (selected || today) && "font-semibold",
                      selected && "text-white dark:text-gray-900",
                      selectable && "text-gray-800 dark:text-gray-100",
                      // !selectable && "text-gray-400 dark:text-gray-300",
                      today && !selected && "text-blue-600 dark:text-blue-400",
                      inRange
                        ? "bg-blue-100 dark:bg-blue-900"
                        : "hover:bg-gray-100 dark:hover:bg-gray-800",
                      weekIndex === 0 && dayIdx === 0 ? "rounded-tl-lg" : "",
                      weekIndex === 0 && dayIdx === 6 ? "rounded-tr-lg" : "",
                      weekIndex === calendar.weeks.length - 1 && dayIdx === 0
                        ? "rounded-bl-lg"
                        : "",
                      weekIndex === calendar.weeks.length - 1 && dayIdx === 6
                        ? "rounded-br-lg"
                        : ""
                    )}
                    disabled={!selectable}
                  >
                    <time
                      dateTime={date.getDate()}
                      className={classNames(
                        "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                        selected &&
                          today &&
                          "initial:bg-blue-600 initial:dark:bg-blue-200",
                        selected &&
                          !today &&
                          "initial:bg-gray-900 initial:dark:bg-gray-50"
                      )}
                    >
                      {selectable ? date.getDate() : date.getDate()}
                    </time>
                  </button>
                );
              })
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
