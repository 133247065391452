import { createSlice } from "@reduxjs/toolkit";

export interface TeamState {
  loadedTeams: any;
  teams: any[];
  environments: any[];
  currentTeam: any;
  currentEnvironment: any;
  settings: any;
}

const initialState: TeamState = {
  loadedTeams: false,
  teams: [],
  environments: [],
  currentTeam: null,
  currentEnvironment: null,
  settings: {},
};

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setLoadedTeams: (state, action) => {
      state.loadedTeams = true;
    },

    setTeams: (state, action) => {
      state.teams = action.payload;
    },

    setTeamSettings: (state, action) => {
      state.settings = action.payload;
    },

    setCurrentTeam: (state, action) => {
      state.currentTeam = action.payload;
    },

    setTeamEnvironments: (state, action) => {
      state.environments = action.payload;
    },

    setCurrentEnvironment: (state, action) => {
      state.currentEnvironment = action.payload;
    },

    clearEnvironments: (state) => {
      state.environments = [];
      state.currentEnvironment = null;
    },

    clearTeamSlice: (state) => {
      state.loadedTeams = false;
      state.teams = [];
      state.environments = [];
      state.currentTeam = null;
      state.currentEnvironment = null;
      state.settings = {};
    },
  },
});

export const {
  setTeams,
  setCurrentTeam,
  clearTeamSlice,
  setTeamEnvironments,
  setCurrentEnvironment,
  clearEnvironments,
  setLoadedTeams,
  setTeamSettings
} = teamSlice.actions;

export default teamSlice.reducer;
