import React, {
  useState,
  useEffect,
  useImperativeHandle,
  ReactText,
} from "react";
import useDataTable from "../useDataTable";
import classNames from "../../react-utils/classNames";
import THead from "./THead";
import TRow from "./TRow";
import Filter from "./Filter";
import { PlainButton } from "../../react-buttons";
import PrevNextFullPaginator from "../PrevNextFullPaginator";

const Paginator = () => {
  return (
    <div className="w-full h-16 sticky bottom-0 left-0 right-0">
      <nav
        className="bg-white dark:bg-gray-900 px-4 py-3 flex items-center justify-between border-t border-gray-200 dark:border-gray-800 sm:px-6"
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700 dark:text-gray-200">
            Showing <span className="font-medium">1</span> to{" "}
            <span className="font-medium">10</span> of{" "}
            <span className="font-medium">20</span> results
          </p>
        </div>
        <div className="flex-1 flex justify-between sm:justify-end">
          <PlainButton>Previous</PlainButton>
          <PlainButton additionalClasses="ml-3">Next</PlainButton>
        </div>
      </nav>
    </div>
  );
};

const Table = React.forwardRef(function Table(
  {
    config,
    sortColumn,
    dataSource,
    perPage,
    params,
    sortOrder = "asc",
    EmptyState = () => {
      return <div className="py-32 text-2xl font-medium">No Data</div>;
    },
    LoadingState = () => {
      return <div className="py-32 text-2xl font-medium">Loading...</div>;
    },
    ...props
  },
  ref
) {
  const {
    orderBy,
    setOrderBy,
    loading,
    setLoading,
    data,
    setRefresh,
    setCurrentPage,
    totalRecords,
    filters,
    applyFilter,
  } = useDataTable({
    config,
    sortOrder,
    sortColumn,
    dataSource,
    perPage,
    params,
  });

  useImperativeHandle(ref, () => {
    return {
      refresh: () => {
        setRefresh(true);
      },
      manualSetLoading: (v: React.SetStateAction<boolean>) => {
        setLoading(v);
      },
      setCurrentPage: (page: React.SetStateAction<number>) => {
        setCurrentPage(page);
      },
    };
  });

  // console.log("loading", loading);

  return (
    <>
      <div className="flex flex-col">
        <div>
          <div className="inline-block min-w-full align-middle">
            <div
              className={classNames(
                "initial:md:rounded-md",
                "overflow-hidden ring-1 ring-black ring-opacity-5 initial:border border-gray-200 initial:dark:border dark:border-gray-700",
                config.componentWrapperClasses
              )}
            >
              <table className="min-w-full">
                {!config.disableHeader && <THead config={config} />}

                <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-100 dark:divide-gray-800">
                  {config.filterable && (
                    <tr
                      className={classNames(
                        config.filterRowClasses || "bg-gray-50 dark:bg-gray-800"
                      )}
                    >
                      {config.columns.map((row, i) => (
                        <Filter
                          filters={filters}
                          item={row}
                          key={`filter${i}`}
                          applyFilter={(v) => {
                            applyFilter(row, v);
                          }}
                        />
                      ))}
                    </tr>
                  )}

                  {loading &&
                    [1, 2, 3].map((row, j) => (
                      <tr
                        className="bg-white dark:bg-gray-900"
                        key={`loading-${j}`}
                      >
                        {config.columns.map((row, i) => (
                          <td
                            key={`loading-${j}-td-${i}`}
                            className="px-6 py-4 whitespace-nowrap text-sm animate-pulse"
                          >
                            <div className="bg-gray-100 dark:bg-gray-800 rounded-xs h-6"></div>
                          </td>
                        ))}
                      </tr>
                    ))}

                  {!loading && data.length === 0 && (
                    <tr className="text-center ">
                      <td colSpan={config.columns.length}>
                        <EmptyState />
                      </td>
                    </tr>
                  )}

                  {!loading && data.length ? (
                    data.map((row, i) => (
                      <TRow
                        key={`tr-${i}`}
                        uniqueKey={`tr-${i}`}
                        dataItem={row}
                        config={config}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>

              <PrevNextFullPaginator
                totalRecords={totalRecords}
                pageLimit={perPage}
                pageNeighbours={1}
                onPageChanged={(data) => {
                  setCurrentPage(data.currentPage);
                  // setCurrentPage(data.currentPage);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export { Table, Paginator };
