import React, { useState, useEffect } from "react";
import classNames from "../react-utils/classNames";
import usePaginator from "./usePaginator";

export default function ({
  totalRecords,
  pageLimit,
  pageNeighbours,
  onPageChanged,
}) {
  const { gotoPage, currentPage } = usePaginator({
    totalRecords,
    pageLimit,
    pageNeighbours,
    onPageChanged,
  });

  return (
    <>
      <div className="w-full h-10 sticky bottom-0 left-0 right-0">
        <div className="border-t border-b border-gray-200 dark:border-gray-800 bg-gray-50 dark:bg-[#192130] px-6 py-2 text-sm font-medium text-gray-500 dark:text-gray-300 flex justify-between items-center">
          <button
            type="button"
            onClick={() => {
              gotoPage(1);
            }}
            disabled={currentPage === 1}
            className="disabled:opacity-50 dark:hover:text-gray-200 hover:text-gray-600"
          >
            First
          </button>
          <button
            type="button"
            onClick={() => {
              gotoPage(currentPage - 1);
            }}
            disabled={currentPage === 1}
            className="disabled:opacity-50 dark:hover:text-gray-200 hover:text-gray-600"
          >
            Prev
          </button>
          <button
            type="button"
            onClick={() => {
              gotoPage(currentPage + 1);
            }}
            className="disabled:opacity-50 dark:hover:text-gray-200 hover:text-gray-600"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
}
