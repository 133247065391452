import { setAccessToken } from "./cookieHelper";
import { login } from "../auth/login";
import { withIronSessionApiRoute } from "iron-session/next";
import { sessionOptions } from "./session";

const mockLogin = withIronSessionApiRoute(async (req, res) => {
  try {
    let response = await login({
      type: "email",
      value: process.env.NEXT_PUBLIC_AUTH_USERNAME,
      password: process.env.NEXT_PUBLIC_AUTH_PASSWORD,
    });

    res.setHeader("Set-Cookie", [setAccessToken(response["access_token"])]);

    req.session.token = response;
    await req.session.save();
    res.status(200).json(response);
  } catch (error) {
    console.log("error in nextjs", error);
    return res.status(422).json(error);
  }
}, sessionOptions);

export { mockLogin };
