import classNames from "../react-utils/classNames";
import PulseLoader from "../react-loaders/pulse";
import { themeScheme } from "utils";

const Card = ({ title, description, loaded = true, ...props }) => {
  return (
    <div
      className={classNames(
        themeScheme.border,
        "overflow-hidden initial:sm:rounded-lg",
        props.extraClasses || ""
      )}
    >
      <div
        className={classNames(
          themeScheme.border,
          "px-4 py-5 border-0 border-b flex justify-between"
        )}
      >
        <div>
          <h3
            className={classNames(
              themeScheme.text,
              "text-base leading-6 font-medium "
            )}
          >
            {title}
          </h3>
          {description !== "" && (
            <p
              className={classNames(
                themeScheme.secondaryText,
                "mt-1 max-w-2xl text-sm"
              )}
            >
              {description}
            </p>
          )}
        </div>
        {props.rightComponent}
      </div>
      {!loaded && <PulseLoader />}
      {loaded && <>{props.children}</>}
    </div>
  );
};

export default Card;
