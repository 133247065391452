import React, { useState } from "react";

export default function Copyable({ whatToCopy, label }) {
  const [copyText, setCopyText] = useState("Click to Copy");

  return (
    <div className="relative group">
      <div
        className="inline-flex items-center px-4 py-0.5 rounded-full text-sm font-medium bg-blue-100 dark:bg-blue-800 text-blue-800 dark:text-blue-100 cursor-pointer"
        onClick={() => {
          navigator.clipboard.writeText(whatToCopy);
          setCopyText("Copied");
        }}
        onMouseLeave={() => {
          setCopyText("Click to Copy");
        }}
      >
        {label()}
      </div>
      <div className="w-full rounded-md absolute top-0 flex-col items-center mt-7 hidden group-hover:flex">
        <div className="w-3 h-3 rotate-45 border bg-gray-100 border-gray-100 dark:bg-gray-800 dark:border-gray-800"></div>
        <span className="-mt-2 relative z-10 p-2 text-xs leading-none border text-gray-800 dark:text-gray-50 whitespace-no-wrap bg-gray-100 border-gray-100 dark:bg-gray-800 dark:border-gray-800 shadow-lg rounded-md">
          {copyText}
        </span>
      </div>
    </div>
  );
}
