import React, { useEffect, useState } from "react";
import { MenuAlt2Icon } from "@heroicons/react/outline";
import {
  BaseLoader,
  useAuth,
  SessionMessageWrapper,
  SidebarWithIcons,
} from "ui";
import { useSelector, shallowEqual } from "react-redux";
import {
  CogIcon,
  HomeIcon,
  ShieldCheckIcon,
  QuestionMarkCircleIcon,
  KeyIcon,
  UserIcon,
  LogoutIcon,
} from "@heroicons/react/outline";
import { useRouter } from "next/router";
import Link from "next/link";

const navigation = [
  { name: "Home", href: "/", icon: HomeIcon },
  { name: "Security", href: "/security", icon: ShieldCheckIcon },
  { name: "Password", href: "/password", icon: KeyIcon },
  { name: "Profile", href: "/profile", icon: UserIcon },
];

const secondaryNavigation = [
  { name: "Help", href: "/help", icon: QuestionMarkCircleIcon },
  { name: "Logout", href: "/logout", icon: CogIcon },
];

const Wrapper = (props) => {
  const router = useRouter();

  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { token } = useAuth(() => {
    router.push("/logout");
  });

  if (!token || token.isLoggedIn === false || user === null) {
    return (
      <main className="justify-center max-w-7xl mx-auto flex flex-col items-center min-h-screen">
        <BaseLoader message="Loading..." />
      </main>
    );
  }

  return (
    <SessionMessageWrapper>
      <div className="relative h-screen bg-white">
        <div className="flex h-screen">
          <SidebarWithIcons
            logo={() => {
              return "Teurons";
            }}
            sidebarOpen={sidebarOpen}
            onPress={(value) => {
              setSidebarOpen(value);
            }}
            navigation={navigation}
            router={router}
            LinkRenderer={(props) => {
              let { href, children, ...rest } = props;
              return (
                <Link href={href}>
                  <a {...rest}>{children}</a>
                </Link>
              );
            }}
            logoutNavigation={{
              name: "Logout",
              href: "/logout",
              icon: LogoutIcon,
            }}
            user={user}
          />

          <div className="flex-1 flex flex-col">
            <MobileNav onPress={() => setSidebarOpen(true)} />
            <div className="flex-1 max-h-screen overflow-y-scroll p-5">
              {props.children}
            </div>
            <footer className="h-10 bg-white p-2 border-t text-xs flex flex-col md:flex-row items-center">
              <span className="font-medium mr-2">
                Current Time: {user.now}{" "}
              </span>
              <span>(Change timezone if this is not correct)</span>
            </footer>
          </div>
        </div>
      </div>
    </SessionMessageWrapper>
  );
};

const MobileNav = ({ onPress }) => {
  return (
    <div className="w-full max-w-4xl mx-auto md:px-8 xl:px-0">
      <div className="md:hidden bg-white border-b border-gray-200 flex items-center">
        <button
          type="button"
          className="border-r border-gray-200 px-4 py-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-violet-500 md:hidden"
          onClick={() => onPress()}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <h3 className="ml-4 font-bold text-2xl tracking-wide">teurons</h3>
      </div>
    </div>
  );
};

export default Wrapper;
