import classNames from "../../react-utils/classNames";
import { themeScheme } from "utils";
import { SupportIcon } from "@heroicons/react/outline";
import { SunIcon, MoonIcon } from "@heroicons/react/solid";

// A sidebar has three sections: Header, Nav, Footer
// You can use Header for Logo
// Nav for some navigation
// Footer for some navigation
// Header and Nav would be in flex-1
// Footer would be in flex-shrink-0

const Header = (props) => {
  return (
    <div
      className={`${themeScheme.bg} h-16 flex flex-col items-center justify-center ${themeScheme.border} border-0 border-b`}
    >
      {props.logo()}
    </div>
  );
};

const Nav = (props) => {
  const LinkRenderer = props.LinkRenderer;

  return (
    <nav aria-label="Sidebar" className="flex-1 mt-2 w-full px-2 space-y-1">
      {props.navigation.map((item) => (
        <LinkRenderer
          key={item.name}
          href={item.href}
          className={classNames(
            item.current
              ? "text-blue-500 font-semibold"
              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white",
            "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
          )}
          aria-current={item.current ? "page" : undefined}
          item={item}
        >
          <item.icon
            className={classNames(
              item.current
                ? "text-blue-500"
                : "text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-300",
              "h-6 w-6"
            )}
            aria-hidden="true"
          />
          <span className="mt-2">{item.name}</span>
        </LinkRenderer>
      ))}
    </nav>
  );
};

const Footer = (props) => {
  const LinkRenderer = props.LinkRenderer;

  return (
    <>
      <div className="group w-full px-3 rounded-md flex flex-col items-center cursor-pointer">
        <button
          type="button"
          onClick={() => {
            if (window.Beacon) {
              window.Beacon("toggle");
            }
          }}
          className={
            "text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
          }
        >
          <SupportIcon
            className={"text-gray-400 h-8 w-8 mx-auto p-1 rounded-full"}
          />
          <span className="mt-1">Support</span>
        </button>
      </div>

      <div className="group w-full px-3 rounded-md flex flex-col items-center cursor-pointer hidden">
        <button
          onClick={() => {
            props.setTheme(props.theme === "light" ? "dark" : "light");
          }}
          className={
            "text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
          }
        >
          {props.theme === "dark" && (
            <MoonIcon
              className={
                "text-blue-400 h-8 w-8 mx-auto p-1 rounded-full hover:bg-blue-800"
              }
            />
          )}

          {props.theme === "light" && (
            <SunIcon
              className={
                "text-blue-400 h-8 w-8 p-1 mx-auto rounded-full hover:bg-blue-50"
              }
            />
          )}
          <span className="mt-1">Theme</span>
        </button>
      </div>

      {props.logoutNavigation && (
        <div className="group w-full p-3 rounded-md flex flex-col items-center cursor-pointer">
          <LinkRenderer
            key={"key_logout"}
            href={props.logoutNavigation.href}
            className={
              "text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
            }
            item={props.logoutNavigation.icon}
          >
            <props.logoutNavigation.icon
              className={classNames(
                "text-gray-400 group-hover:text-gray-500 dark:text-gray-400 dark:group-hover:text-gray-300 h-6 w-6"
              )}
              aria-hidden="true"
            />
            <span className="mt-2">{props.logoutNavigation.name}</span>
          </LinkRenderer>
        </div>
      )}
      <div
        className="w-full mx-auto text-center text-gray-400 px-1"
        style={{
          fontSize: "10px",
        }}
      >
        {props?.user?.profile?.timezone || "GMT"}
        <br></br>
        {props.currentTime || ""}
      </div>
    </>
  );
};

const SidebarWithIcons = (props: any) => {
  return (
    <div
      className={`hidden lg:flex lg:flex-shrink-0 ${themeScheme.border} border-0 border-r`}
    >
      <div className="flex flex-col w-28">
        <div
          className={`flex-1 flex flex-col min-h-0 overflow-y-auto ${themeScheme.bg}`}
        >
          <div className="flex-1">
            <Header {...props} />
            <Nav {...props} />
          </div>
          <div className="flex-shrink-0 flex flex-col pb-1 space-y-2">
            <Footer {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarWithIcons;
