import React, {
  Fragment,
  useState,
  useEffect,
  useImperativeHandle,
} from "react";
import useDataTable from "../useDataTable";
import FirstLastPaginator from "../FirstLastPaginator";
import classNames from "../../react-utils/classNames";

const SingleColumnTable = React.forwardRef(function SingleColumnTable(
  {
    config = {},
    sortColumn,
    dataSource,
    perPage,
    params,
    sortOrder = "asc",
    Heading = () => {
      return (
        <div className="h-16 bg-white dark:bg-gray-900 px-6 flex flex-col justify-center">
          <div className="flex items-baseline space-x-3">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white">
              Inbox
            </h2>
            <p className="text-sm font-medium text-gray-500 dark:text-gray-300">
              10 messages
            </p>
          </div>
        </div>
      );
    },
    EmptyState = () => {
      return <div className="py-32 text-2xl font-medium">No Data</div>;
    },
    LoadingState = () => {
      return <div className="py-32 text-2xl font-medium">Loading...</div>;
    },
    ItemRenderer,
    ...props
  },
  ref
) {
  const {
    loading,
    data,
    query,
    setQuery,
    currentPage,
    setCurrentPage,
    totalRecords,
    pagination,
    setRefresh,
  } = useDataTable({
    sortOrder,
    sortColumn,
    dataSource,
    perPage,
    params,
  });

  const [queryText, setQueryText] = useState("");

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setQuery(queryText);
    }, 500);
    return () => clearTimeout(debounceTimer);
  }, [queryText]);

  useImperativeHandle(ref, () => {
    return {
      refresh: () => {
        setRefresh(true);
      },
    };
  });

  return (
    <div className="h-full relative flex flex-col w-96 border-r border-gray-200 dark:border-gray-800 bg-gray-100 dark:bg-gray-900">
      <div
        className={classNames(
          "flex-shrink-0",
          !props?.enableQuerySearch ? "border-b dark:border-gray-800" : ""
        )}
      >
        <Heading currentPage={currentPage} pagination={pagination} />

        {props?.enableQuerySearch && (
          <div>
            <input
              type="text"
              value={queryText}
              onChange={(e) => {
                setQueryText(e.target.value);
              }}
              placeholder="Type to Filter.."
              className="border-gray-200 dark:border-gray-800 px-6 py-3 focus:ring-gray-200 focus:border-gray-200 dark:focus:ring-gray-800 dark:focus:border-gray-800 block w-full sm:text-sm text-xs border-1 bg-white dark:bg-gray-900"
            />
          </div>
        )}
      </div>

      <nav
        aria-label="Message list"
        className="max-h-screen flex-1 overflow-y-scroll"
      >
        {loading && (
          <div className="text-center bg-white dark:bg-gray-900">
            <LoadingState />
          </div>
        )}

        {!loading && data.length === 0 && (
          <div className="text-center bg-white dark:bg-gray-900">
            <EmptyState />
          </div>
        )}

        <ul
          role="list"
          className="border-b border-gray-200 dark:border-gray-800 divide-y divide-gray-200 dark:divide-gray-800"
        >
          {!loading && data.length ? (
            data.map((row, i) => (
              <li key={`li-${i}`}>
                <ItemRenderer item={row} />
              </li>
            ))
          ) : (
            <></>
          )}
        </ul>
      </nav>

      {totalRecords / perPage > 1 && (
        <FirstLastPaginator
          totalRecords={totalRecords}
          pageLimit={perPage}
          pageNeighbours={1}
          onPageChanged={(data) => {
            setCurrentPage(data.currentPage);
          }}
        />
      )}

      {/* <div className="w-full h-10 sticky bottom-0 left-0 right-0">
        <div className="border-t border-b border-gray-200 dark:border-gray-800 bg-gray-50 dark:bg-[#192130] px-6 py-2 text-sm font-medium text-gray-500 dark:text-gray-300 flex justify-between items-center">
          <div>First</div>
          <div>Prev</div>
          <div>Next</div>
        </div>
      </div> */}
    </div>
  );
});

export default SingleColumnTable;
