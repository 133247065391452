import React, { useState } from "react";

export default function HasTooltip({ label, tooltipText, direction = "top" }) {
  if (direction === "top") {
    return (
      <div className="relative inline-block group">
        <Top tooltipText={tooltipText} />
        <div className="cursor-pointer">{label()}</div>
      </div>
    );
  }

  if (direction === "left") {
    return (
      <div className="relative inline-block group">
        <Left tooltipText={tooltipText} />
        <div className="cursor-pointer">{label()}</div>
      </div>
    );
  }
}

const Top = ({ tooltipText }) => {
  return (
    <div className="w-full rounded-md absolute bottom-0 flex-col items-center mb-5 hidden group-hover:flex">
      <span className="relative z-10 p-2 text-xs leading-none border text-gray-800 dark:text-gray-50 whitespace-no-wrap bg-gray-100 border-gray-100 dark:bg-gray-800 dark:border-gray-800 shadow-lg rounded-md">
        {tooltipText}
      </span>
      <div className="-mt-2 w-3 h-3 rotate-45 border bg-gray-100 border-gray-100 dark:bg-gray-800 dark:border-gray-800"></div>
    </div>
  );
};

const Left = ({ tooltipText }) => {
  return (
    <div className="w-full rounded-md top-0 flex-row items-center mr-32 flex group-hover:flex">
      <span className=" relative z-10 p-2 text-xs leading-none border text-gray-800 dark:text-gray-50 whitespace-no-wrap bg-gray-100 border-gray-100 dark:bg-gray-800 dark:border-gray-800 shadow-lg rounded-md">
        {tooltipText}
      </span>
      <div className="-ml-2 w-3 h-3 rotate-45 border bg-red-800 border-red-800 dark:bg-red-800 dark:border-red-800"></div>
    </div>
  );
};
