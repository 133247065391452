import { createSlice } from "@reduxjs/toolkit";

export interface AuthState {
  user: any;
  accessToken: any;
  verifyUsername: any;
  resetPasswordUsername: any;
  redirectAppUrl: any;
}

const initialState: AuthState = {
  user: null,
  accessToken: null,
  verifyUsername: null,
  resetPasswordUsername: null,
  redirectAppUrl: "/", // default: /, value: some-url, no-action: ""
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setResetPasswordUsername: (state, action) => {
      state.resetPasswordUsername = action.payload;
    },

    clearResetPasswordUsername: (state, action) => {
      state.resetPasswordUsername = null;
    },

    setVerifyUsername: (state, action) => {
      state.verifyUsername = action.payload;
    },

    clearVerifyUsername: (state, action) => {
      state.verifyUsername = null;
    },

    setUser: (state, action) => {
      state.user = action.payload;
    },

    setToken: (state, action) => {
      state.accessToken = action.payload;
    },

    clearAuth: (state) => {
      state.user = null;
      state.verifyUsername = null;
      state.accessToken = null;
    },

    setRedirectAppUrl: (state, action) => {
      state.redirectAppUrl = action.payload;
    },

    clearRedirectAppUrl: (state) => {
      state.redirectAppUrl = "";
    },
  },
});

export const {
  clearAuth,
  setUser,
  setToken,
  setVerifyUsername,
  clearVerifyUsername,
  setResetPasswordUsername,
  clearResetPasswordUsername,
  setRedirectAppUrl,
  clearRedirectAppUrl,
} = authSlice.actions;

export default authSlice.reducer;
