import cookie from "cookie";
import { removeAccessToken } from "./cookieHelper";
import { withIronSessionApiRoute } from "iron-session/next";
import { sessionOptions } from "./session";

const nextUser = withIronSessionApiRoute(async (req, res) => {
  // console.log("nextUser-headers-stringified", JSON.stringify(req.headers));
  // console.log("nextUser-headers", req.headers);
  // console.log("nextUser-called", req.headers.cookie);
  if (req.headers.cookie === undefined) {
    // console.log("false-1");
    return res.json({
      isLoggedIn: false,
    });
  }

  try {
    // console.log(
    //   "cookie.parse(req.headers.cookie)",
    //   cookie.parse(req.headers.cookie)
    // );
    const tokens = cookie.parse(req.headers.cookie);
    const token = tokens.access_token;

    if (token) {
      const url = process.env.NEXT_PUBLIC_API_URL + "/access/user";

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      const data = await response.json();

      if (response.ok) {
        console.log("response is OK", token);
        res.json({
          isLoggedIn: true,
          access_token: token,
          data,
        });
      } else {
        console.log("Response is not okay", data);
        res.setHeader("Set-Cookie", [removeAccessToken()]);
        res.json({
          isLoggedIn: false,
          reason: "Response not ok",
        });
      }
    } else {
      res.setHeader("Set-Cookie", [removeAccessToken()]);
      res.json({
        isLoggedIn: false,
        reason: "No Token",
      });
    }
  } catch (error) {
    res.setHeader("Set-Cookie", [removeAccessToken()]);
    res.json({
      isLoggedIn: false,
      reason: "Something went wrong",
    });
  }
}, sessionOptions);

export { nextUser };
