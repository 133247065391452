import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { useDayzed } from "dayzed";
import { DateTime } from "luxon";
import React, { useState, useEffect } from "react";
import classNames from "../react-utils/classNames";

const monthNamesShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function SingleDatePicker({ onChange, userTimezone, selected }) {
  // console.log("selected", selected);

  const [selectedDate, setSelectedDate] = useState(null);
  // const [days, setDays] = useState([]);

  useEffect(() => {
    if (selected !== null) {
      setSelectedDate(new Date(convertWithTimezone(new Date(selected))));
    }
  }, []);

  const convertWithTimezone = (date) => {
    const datepickerDate = DateTime.fromJSDate(date);

    const newDate = DateTime.fromObject(
      {
        year: datepickerDate.year,
        month: datepickerDate.month,
        day: datepickerDate.day,
      },
      { zone: userTimezone }
    );

    return newDate.toISO();
  };

  const handleOnDateSelected = ({ selected, selectable, date }) => {
    onChange(convertWithTimezone(date));
    setSelectedDate(date);
  };

  let dayzedData = useDayzed({
    date: new Date(),
    maxDate: new Date(),
    selected: selectedDate,
    onDateSelected: handleOnDateSelected,
    showOutsideDays: true,
  });

  return <Calendar {...dayzedData} />;
}

const Calendar = ({
  calendars,
  getBackProps,
  getForwardProps,
  getDateProps,
}) => {
  return (
    <div className="bg-white dark:bg-gray-900 rounded-lg">
      {calendars.map((calendar) => (
        <div
          className="text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 xl:col-start-9"
          key={`${calendar.month}${calendar.year}`}
        >
          <div className="flex items-center text-gray-800 dark:text-gray-100 py-2 border-b border-t border-gray-200 dark:border-gray-800 ">
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-200 disabled:opacity-50"
              {...getBackProps({ calendars })}
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className="flex-auto font-semibold">
              {monthNamesShort[calendar.month]} {calendar.year}
            </div>
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 dark:text-gray-300 hover:text-gray-500 dark:hover:text-gray-200 disabled:opacity-50"
              {...getForwardProps({ calendars })}
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>

          <div className="mt-3 grid grid-cols-7 text-xs leading-6 text-gray-500 dark:text-gray-300 bg-white dark:bg-gray-900">
            <div>S</div>
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
          </div>

          <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 dark:bg-gray-700 text-sm ring-1 ring-gray-200 dark:ring-gray-700">
            {calendar.weeks.map((week, weekIndex) =>
              week.map((dateObj, dayIdx) => {
                let key = `${calendar.month}${calendar.year}${weekIndex}${dayIdx}`;

                let {
                  date,
                  selected,
                  selectable,
                  today,
                  prevMonth,
                  nextMonth,
                } = dateObj;
                let isCurrentMonth = !prevMonth && !nextMonth;
                selectable = selectable && isCurrentMonth;

                return (
                  <button
                    type="button"
                    key={key}
                    {...getDateProps({ dateObj })}
                    className={classNames(
                      "py-1.5 focus:z-10 disabled:opacity-50",
                      selectable
                        ? "bg-white dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800"
                        : "bg-gray-50 dark:bg-gray-800",
                      (selected || today) && "font-semibold",
                      selected && "text-white dark:text-gray-900",
                      selectable && "text-gray-800 dark:text-gray-100",
                      // !selectable && "text-gray-400 dark:text-gray-300",
                      today && !selected && "text-blue-600 dark:text-blue-400",
                      weekIndex === 0 && dayIdx === 0 && "rounded-tl-lg",
                      weekIndex === 0 && dayIdx === 6 && "rounded-tr-lg",
                      weekIndex === calendar.weeks.length - 1 &&
                        dayIdx === 0 &&
                        "rounded-bl-lg",
                      weekIndex === calendar.weeks.length - 1 &&
                        dayIdx === 6 &&
                        "rounded-br-lg"
                    )}
                    disabled={!selectable}
                  >
                    <time
                      dateTime={date.getDate()}
                      className={classNames(
                        "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                        selected && today && "bg-blue-600 dark:bg-blue-200",
                        selected && !today && "bg-gray-900 dark:bg-gray-50"
                      )}
                    >
                      {selectable ? date.getDate() : date.getDate()}
                    </time>
                  </button>
                );
              })
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
