import { axiosObject } from "../setup";

function environmentsUserCanViewEnvironments(xTeamId = null) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/environments`;

    if (xTeamId) {
      axiosObject
        .get(uri, { headers: { "X-TEAM-IDENTIFIER": xTeamId } })
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          // console.log("errors in environmentsUserCanViewEnvironments", errors);

          if (errors.response.status === 422) {
            reject(errors.response.data.errors);
          }
          reject(errors);
        });
    } else {
      axiosObject
        .get(uri)
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          // console.log("errors in environmentsUserCanViewEnvironments", errors);

          if (errors.response.status === 422) {
            reject(errors.response.data.errors);
          }
          reject(errors);
        });
    }
  });
}

function environmentsUserCanAddProvider(environmentUuid, payload) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/environments/${environmentUuid}/providers`;

    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function environmentsUserCanCreateEnvironment(payload) {
  return new Promise((resolve, reject) => {
    let uri = "/neptune/environments";

    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function environmentsUserCanDeleteEnvironment(uuid) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/environments/${uuid}`;

    axiosObject
      .delete(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function environmentsUserCanDeleteProvidersOfEnvironment(
  enviromentUuid,
  providerUuid
) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/environments/${enviromentUuid}/providers`;

    let payload = {
      provider_uuid: providerUuid,
    };

    axiosObject
      .delete(uri, { data: payload })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function environmentsUserCanUpdateEnvironment(uuid, payload) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/environments/${uuid}`;

    axiosObject
      .put(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function environmentsUserCanViewEnvironment(uuid) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/environments/${uuid}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function environmentsUserCanViewProvidersOfEnvironment(environment_uuid) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/environments/${environment_uuid}/providers`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

export {
  environmentsUserCanViewEnvironments,
  environmentsUserCanAddProvider,
  environmentsUserCanCreateEnvironment,
  environmentsUserCanDeleteEnvironment,
  environmentsUserCanDeleteProvidersOfEnvironment,
  environmentsUserCanUpdateEnvironment,
  environmentsUserCanViewEnvironment,
  environmentsUserCanViewProvidersOfEnvironment,
};
