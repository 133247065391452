import React, { useEffect, useState } from "react";
import useToken from "./useToken";
import { useDispatch } from "react-redux";
import { setToken, setUser } from "store";
import { getUser, clearAppLocalStorageOnLogout } from "services";

const useAuth = function (callback = () => {}, redirectTo = "/login") {
  const dispatch = useDispatch();

  const { token } = useToken({ redirectTo: redirectTo });
  const [authCheckComplete, setAuthCheckComplete] = useState(false);

  useEffect(() => {
    (async () => {
      if (token && token.access_token) {
        dispatch(setToken(token.access_token));
        try {
          let userResponse = await getUser();

          if (typeof window !== undefined) {
            let authFromLocalStorage =
              window.localStorage.getItem("persist:root");

            authFromLocalStorage = JSON.parse(authFromLocalStorage);

            let userFromLocalStorage = JSON.parse(authFromLocalStorage.auth);

            if (userFromLocalStorage?.user?.uuid !== userResponse.uuid) {
              /* clear local storage here */
              clearAppLocalStorageOnLogout();
            }
            dispatch(setToken(token.access_token));
            dispatch(setUser(userResponse));
            setAuthCheckComplete(true);
          }
        } catch (error) {
          console.log("useAuth====>userAuth-error-1234", error);
          setAuthCheckComplete(true);
          callback();
        }
      }
    })();
  }, [token]);

  return {
    token,
    authCheckComplete,
  };
};

export default useAuth;
