import React from "react";
import classNames from "../react-utils/classNames";
import { themeScheme } from "utils";

const PulseLoader = function ({ count = 1 }) {
  return (
    <div className={classNames("whitespace-nowrap text-xl animate-pulse py-2")}>
      <div
        className={classNames(themeScheme.secondaryBg, "rounded-xs h-6")}
      ></div>
    </div>
  );
};

export default PulseLoader;
