/* This example requires Tailwind CSS v2.0+ */
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  HomeIcon,
} from "@heroicons/react/solid";
import classNames from "../../react-utils/classNames";

const JustLink = (props: any) => {
  let { href, children, ...rest } = props;
  return (
    <a href={href} {...rest}>
      {children}
    </a>
  );
};

export default function Heading({
  title = "Back End Developer",
  LinkRenderer = JustLink,
  breadcrumbs = [],
  fontSize = "text-2xl",
  helperText = () => {},
  ...props
}) {
  return (
    <div className="border-0 border-gray-100 dark:border-gray-700">
      <div className={classNames(props.paddingClasses || "px-6 py-4")}>
        <div className="md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            {breadcrumbs.length > 0 && (
              <div>
                <nav className="sm:hidden" aria-label="Back">
                  <a
                    href="#"
                    className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    <ChevronLeftIcon
                      className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    Back
                  </a>
                </nav>

                <nav className="hidden sm:flex" aria-label="Breadcrumb">
                  <ol role="list" className="flex items-center space-x-4">
                    <li>
                      <div className="flex">
                        <LinkRenderer
                          href="/"
                          className="text-xs font-medium text-gray-500 dark:text-gray-400 dark:hover:text-gray-300 hover:text-gray-700"
                        >
                          <HomeIcon
                            className="flex-shrink-0 h-4 w-4 text-gray-400 dark:text-gray-500"
                            aria-hidden="true"
                          />
                        </LinkRenderer>
                      </div>
                    </li>

                    {breadcrumbs.map((breadcrumb, i) => (
                      <li key={`breadcrumb-${i}`}>
                        <div className="flex items-center">
                          <ChevronRightIcon
                            className="flex-shrink-0 h-5 w-5 text-gray-400 hidden"
                            aria-hidden="true"
                          />
                          <div className="text-gray-200 dark:text-gray-700">
                            /
                          </div>
                          {breadcrumb.current && (
                            <span className="ml-4 text-sm lowercase font-medium text-gray-400 dark:text-gray-500">
                              {breadcrumb.title}
                            </span>
                          )}

                          {!breadcrumb.current && (
                            <LinkRenderer
                              href={`${breadcrumb.href}`}
                              className="ml-4 text-sm lowercase font-medium text-gray-400 dark:text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                            >
                              {breadcrumb.title}
                            </LinkRenderer>
                          )}
                        </div>
                      </li>
                    ))}
                  </ol>
                </nav>
              </div>
            )}

            {title !== "" && (
              <h2
                className={classNames(
                  "font-medium leading-7 text-gray-800 dark:text-gray-100",
                  breadcrumbs.length > 0 ? "mt-4" : "",
                  fontSize ? fontSize : "text-3xl"
                )}
              >
                {title}
              </h2>
            )}
            <div className="text-gray-700 dark:text-gray-200">
              {helperText()}
            </div>
          </div>
          <div className="flex-shrink-0 flex mt-4 md:mt-0 md:ml-4">
            <div>{props.actions}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
