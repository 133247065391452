import validate from "validate.js";

const baseValidator = function (data, constraints) {
  let jsValidationErrors;

  jsValidationErrors = validate(data, constraints, {
    format: "detailed",
  });

  if (jsValidationErrors === undefined) {
    return true;
  } else {
    let errors = {};

    jsValidationErrors.map((d) => {
      errors[d.attribute] = d.error;
      return d;
    });
    return errors;
  }
};

export default baseValidator;
