import { useEffect } from "react";
import Router from "next/router";
import useSWR from "swr";
import { useDispatch } from "react-redux";
import { clearRedirectAppUrl } from "store";

export default function useToken({
  redirectTo = "",
  redirectIfFound = false,
} = {}) {
  const { data: token, mutate: mutateToken } = useSWR("/api/user");
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log("useToken called, redirectTo", redirectTo);
    // console.log("useToken called, token", token);
    if (redirectTo === "" || !token) return;

    if (
      (redirectTo !== "" && !redirectIfFound && !token?.isLoggedIn) ||
      (redirectIfFound && token?.isLoggedIn)
    ) {
      console.log("useToken redirect from condition", redirectTo);
      dispatch(clearRedirectAppUrl());
      Router.push(redirectTo);
    }
  }, [token, redirectIfFound, redirectTo]);

  return { token, mutateToken };
}
