import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { clearAlertMessage } from "store";
import { useEffect } from "react";
import { SimpleAlert } from "../reusejs";

const SessionMessageWrapper = (props: { children: any }) => {
  const dispatch = useDispatch();

  const { message } = useSelector(
    ({ alert }) => ({
      message: alert.message,
    }),
    shallowEqual
  );

  useEffect(() => {
    (async () => {
      if (message !== "" && message) {
        SimpleAlert({ message: message });
        dispatch(clearAlertMessage());
      } else {
        dispatch(clearAlertMessage());
      }
    })();
  }, [message]);

  return props.children;
};

export default SessionMessageWrapper;
