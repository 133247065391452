import React from "react";
import { useRef } from "react";
import classNames from "../react-utils/classNames";

const FormLabel = ({ children, labelCorner = () => { }, ...props }) => {
  {
    return (
      <div className="flex justify-between">
        <label
          htmlFor={props.htmlFor}
          className={classNames(
            props.formLabelClasses || "block text-sm font-normal",
            props.errorText === undefined
              ? "text-gray-900 dark:text-white"
              : "",
            props.errorText !== undefined
              ? "text-red-600 dark:text-red-200"
              : ""
          )}
        >
          {children}
        </label>
        {labelCorner()}
      </div>
    );
  }
};

export default function BaseInput({
  label = null,
  id = "",
  type = "text",
  placeholder = "Enter text",
  onChange,
  defaultValue = "",
  disabled = false,
  labelCorner = () => { },
  errorText = undefined,
  errorTextRenderer = (text) => {
    if (Array.isArray(text)) {
      return text.join(", ");
    }
    return text;
  },
  helperText = "",
  inputLeftIcon = null,
  inputRightIcon = null,
  FormLabelComponent = (props) => {
    return <FormLabel {...props} />;
  },
  ...props
}) {
  const myRef = useRef(null);

  return (
    <div>
      {label !== null && (
        <FormLabelComponent
          htmlFor={id}
          labelCorner={labelCorner}
          errorText={errorText}
          formLabelClasses={props.formLabelClasses}
        >
          {label}
        </FormLabelComponent>
      )}
      <div className={classNames("relative", label !== null ? "mt-1" : "")}>
        <textarea
          ref={myRef}
          rows={props.rows || 4}
          name={id}
          id={id}
          className={classNames(
            props.basicInputClasses ||
            "rounded text-sm w-full block dark:bg-gray-900 bg-white focus:ring-blue-500 focus:border-blue-500",
            errorText === undefined
              ? props.noErrorClasses ||
              "border border-gray-400 dark:border-gray-700 text-gray-900 dark:text-white"
              : "",
            errorText !== undefined
              ? props.withErrorClasses ||
              "border border-red-600 dark:border-red-200 text-red-900 dark:text-red-200 placeholder-red-300"
              : ""
          )}
          defaultValue={defaultValue}
          onChange={(e) => onChange(e.target.value)}
          {...props}
        />
      </div>
      {helperText !== "" && errorText === undefined && (
        <p
          className={
            props.helperTextClassess ||
            "mt-2 text-sm text-gray-700 dark:text-gray-200"
          }
        >
          {helperText}
        </p>
      )}
      {errorText !== undefined && (
        <p
          className={
            props.errorTextClassess ||
            "mt-2 text-sm text-red-600 dark:text-red-200"
          }
        >
          {errorTextRenderer(errorText)}
        </p>
      )}
    </div>
  );
}
