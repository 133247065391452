import React from "react";
import { useRef, useState, Fragment } from "react";
import classNames from "../react-utils/classNames";
import SingleDatePicker from "../react-dates-times/single";
import RangeDatePicker from "../react-dates-times/range";
import { CalendarIcon } from "@heroicons/react/solid";
import { Dialog, Transition } from "@headlessui/react";
import { useDateHelpers } from "../react-utils/useDateHelpers";
import useOutsideClicker from "../react-utils/useOutsideClicker";

const FormLabel = ({ children, labelCorner = () => {}, ...props }) => {
  {
    return (
      <div className="relative flex justify-between">
        <label
          htmlFor={props.htmlFor}
          className={classNames(
            props.formLabelClasses || "block text-sm font-normal",
            props.errorText === undefined
              ? "text-gray-900 dark:text-white"
              : "",
            props.errorText !== undefined
              ? "text-red-600 dark:text-red-200"
              : ""
          )}
        >
          {children}
        </label>
        {labelCorner()}
      </div>
    );
  }
};

export default function BaseInput({
  label = null,
  id = "",
  type = "text",
  placeholder = "Enter text",
  onChange,
  defaultValue = [],
  disabled = false,
  labelCorner = () => {},
  errorText = undefined,
  errorTextRenderer = (text) => {
    if (Array.isArray(text)) {
      return text.join(", ");
    }
    return text;
  },
  helperText = "",
  userTimezone = "GMT",
  FormLabelComponent = (props) => {
    return <FormLabel {...props} />;
  },
  ...props
}) {
  const myRef = useRef(null);
  const [enable, setEnable] = useState(false);

  // console.log("defaultValue", defaultValue);

  const { getFormattedDate } = useDateHelpers();

  const visRef = useOutsideClicker(() => {
    setEnable(false);
  });

  return (
    <div className="relative" ref={visRef}>
      {label !== null && (
        <FormLabelComponent
          htmlFor={id}
          labelCorner={labelCorner}
          errorText={errorText}
          formLabelClasses={props.formLabelClasses}
        >
          {label}
        </FormLabelComponent>
      )}
      <div
        className={classNames("relative w-64", label !== null ? "mt-1" : "")}
      >
        <button
          className={classNames(
            "flex w-full rounded text-sm px-3 py-2 dark:bg-gray-900 bg-white focus:ring-blue-400 focus:border-blue-400 outline-none items-center",
            errorText === undefined
              ? props.noErrorClasses ||
                  "border border-gray-400 dark:border-gray-700 text-gray-900 dark:text-white"
              : "",
            errorText !== undefined
              ? props.withErrorClasses ||
                  "border border-red-600 dark:border-red-200 text-red-900 dark:text-red-200 placeholder-red-300"
              : ""
          )}
          onClick={() => {
            setEnable(true);
          }}
          onBlur={() => {
            // setEnable(false);
          }}
        >
          <div className="h-5 flex-1 text-left flex justify-between mr-5">
            <span>
              {getFormattedDate(defaultValue[0], false, "dd MMM yyyy")}
            </span>
            <span>-</span>
            <span>
              {getFormattedDate(defaultValue[1], false, "dd MMM yyyy")}
            </span>
          </div>
          <CalendarIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
        </button>
      </div>

      {helperText !== "" && errorText === undefined && (
        <p
          className={
            props.helperTextClassess ||
            "mt-2 text-sm text-gray-900 dark:text-white"
          }
        >
          {helperText}
        </p>
      )}
      {errorText !== undefined && (
        <p
          className={
            props.errorTextClassess ||
            "mt-2 text-sm text-red-600 dark:text-red-200"
          }
        >
          {errorTextRenderer(errorText)}
        </p>
      )}

      {enable && (
        <Transition
          show={enable}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <div className="absolute z-50 block w-full overflow-auto bg-white dark:bg-gray-900 shadow-xl  hover:shadow-xl shadow-gray-200 dark:shadow-gray-800 rounded-lg">
            <RangeDatePicker
              selected={defaultValue}
              userTimezone={userTimezone}
              onChange={(dates) => {
                onChange(dates);
                setEnable(false);
              }}
            />
          </div>
        </Transition>
      )}
    </div>
  );
}
