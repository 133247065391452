import React, { useEffect, useState, useRef } from "react";
import classNames from "../../react-utils/classNames";
import { themeScheme } from "utils";
import {
  MenuIcon,
  BellIcon,
  ChevronDownIcon,
  SelectorIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Fragment } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { SunIcon, MoonIcon } from "@heroicons/react/outline";
import DropdownMenu from "../menu";
import theme from "utils/theme";

const MobileNav = (props) => {
  const LinkRenderer = props.LinkRenderer;

  return (
    <Transition.Root show={props.open || false} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 lg:hidden overflow-y-scoll"
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="hidden sm:block sm:fixed sm:inset-0 sm:bg-gray-600 sm:bg-opacity-75" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transition ease-out duration-150 sm:ease-in-out sm:duration-300"
          enterFrom="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
          enterTo="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
          leave="transition ease-in duration-150 sm:ease-in-out sm:duration-300"
          leaveFrom="transform opacity-100 scale-100 sm:translate-x-0 sm:scale-100 sm:opacity-100"
          leaveTo="transform opacity-0 scale-110 sm:translate-x-full sm:scale-100 sm:opacity-100"
        >
          <nav
            className={`fixed z-40 inset-0 h-full w-full ${themeScheme.bg} sm:inset-y-0 sm:left-auto sm:right-0 sm:max-w-sm sm:w-full sm:shadow-lg overflow-y-scroll`}
            aria-label="Global"
          >
            <div className="h-16 flex items-center justify-between px-4 sm:px-6">
              <LinkRenderer href="/">
                <img
                  className="block h-8 w-auto"
                  src="https://tailwindui.com/img/logos/workflow-mark.svg?color=cyan&shade=400"
                  alt="Workflow"
                />
              </LinkRenderer>
              <button
                type="button"
                className="-mr-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600"
                onClick={() => props.setOpen(false)}
              >
                <span className="sr-only">Close main menu</span>
                <XIcon className="block h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="max-w-8xl mx-auto py-3 px-2 sm:px-4">
              {props.navigation.map((item) => (
                <Fragment key={item.name}>
                  <LinkRenderer
                    href={"/"}
                    className="block rounded-md py-2 px-3 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    {item.name}
                  </LinkRenderer>
                </Fragment>
              ))}

              {props.leftNavigation.map((item) => (
                <Fragment key={item.name}>
                  <LinkRenderer
                    href={"/"}
                    className="block rounded-md py-2 px-3 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    {item.name}
                  </LinkRenderer>
                  {item.children.map((child) => (
                    <LinkRenderer
                      key={child.name}
                      href={"/"}
                      className="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      {child.name}
                    </LinkRenderer>
                  ))}
                </Fragment>
              ))}

              {props.rightNavigation.map((item) => (
                <Fragment key={item.name}>
                  <LinkRenderer
                    href={"/"}
                    className="block rounded-md py-2 px-3 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    {item.name}
                  </LinkRenderer>
                  {item.children.map((child) => (
                    <LinkRenderer
                      key={child.name}
                      href={"/"}
                      className="block rounded-md py-2 pl-5 pr-3 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                    >
                      {child.name}
                    </LinkRenderer>
                  ))}
                </Fragment>
              ))}
            </div>
            <div className="border-t border-gray-200 dark:border-gray-700 pt-4 pb-3">
              <div className="max-w-8xl mx-auto px-4 flex items-center sm:px-6">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={props.user.imageUrl}
                    alt=""
                  />
                </div>
                <div className="ml-3 min-w-0 flex-1">
                  <div className="text-base font-medium text-gray-800 dark:text-gray-100 truncate">
                    {props.user.name}
                  </div>
                  <div className="text-sm font-medium text-gray-500  truncate">
                    {props.user.email}
                  </div>
                </div>
                <button
                  onClick={() => {
                    props.setTheme(props.theme === "light" ? "dark" : "light");
                  }}
                  className="ml-auto flex-shrink-0"
                >
                  {props.theme === "dark" && (
                    <MoonIcon
                      className={classNames("text-blue-400 h-6 w-6 mx-auto")}
                    />
                  )}

                  {props.theme === "light" && (
                    <SunIcon
                      className={classNames("text-blue-400 h-6 w-6 mx-auto")}
                    />
                  )}
                </button>
                {/* <LinkRenderer
                    href="#"
                    className={`ml-auto flex-shrink-0 ${themeScheme.bg} p-2 text-gray-400 hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-300`}
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </LinkRenderer> */}
              </div>
              <div className="mt-3 max-w-8xl mx-auto px-2 space-y-1 sm:px-4">
                {props.userNavigation.map((item) => (
                  <LinkRenderer
                    key={item.name}
                    href={"/"}
                    className="block rounded-md py-2 px-3 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    {item.name}
                  </LinkRenderer>
                ))}
              </div>
            </div>
          </nav>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default MobileNav;
