import React from "react";
// import Neptune from "./applications/neptune";
// import Obrota from "./applications/obrota";
// import Announce from "./applications/announce";
import ActionCard from "./ActionCard";
import NeptuneLogo from "./NeptuneLogo";
/* this will come from backend */
//let allowedTenants = ["neptune", "obrota", "announce"];
let allowedTenants = ["neptune"];

const products = [
  {
    icon: () => (
      <NeptuneLogo className="text-gray-700 dark:text-gray-300 w-full h-6 md:h-8" />
    ),
    name: "Neptune",
    description:
      "A CMS for all your notifications like Email, SMS, Push. View and manage all your user communication in one place. Understand user behaviour via analyzing the events which happened in your application.",
    href: process.env.NEXT_PUBLIC_NEPTUNE_APP_URL,
  },
];
const tenantData = {
  neptune: {
    icon: () => (
      <NeptuneLogo className="text-gray-700 dark:text-gray-300 w-full h-6 md:h-8" />
    ),
    name: "Neptune",
    description:
      "A CMS for all your notifications like Email, SMS, Push. View and manage all your user communication in one place. Understand user behaviour via analyzing the events which happened in your application.",
    appLink: process.env.NEXT_PUBLIC_NEPTUNE_APP_URL,
  },
  announce: {
    title: "Announce",
    description:
      "A CMS for all your notifications like Email, SMS, Push. View and manage all your user communication in one place. Understand user behaviour via analyzing the events which happened in your application.",
    appLink: "#",
  },
  obrota: {
    title: "Obrota",
    description:
      "A CMS for all your notifications like Email, SMS, Push. View and manage all your user communication in one place. Understand user behaviour via analyzing the events which happened in your application.",
    appLink: "#",
  },
};

export default function ApplicationListRenderer() {
  return (
    <div className="flex justify-between items-center flex-col md:flex-row">
      {allowedTenants.length > 0 &&
        products.map((tenant, index) => (
          <div className="mb-4" key={`app-${index}`}>
            <ActionCard
              actions={products}
              action={tenant}
              key={`support-${index}`}
              actionIdx={index}
            />
          </div>
        ))}
    </div>
  );
}
