import React, { useState, useEffect, useImperativeHandle } from "react";
import classNames from "../react-utils/classNames";
import usePaginator from "./usePaginator";
import { PlainButton } from "../react-buttons";

const PrevNextFullPaginator = React.forwardRef(function PrevNextFullPaginator(
  { sourceRef, pageLimit, onPageChanged, totalRecords },
  ref
) {
  // const [totalRecords, setTotalRecords] = useState(0);

  const { gotoPage, currentPage } = usePaginator({
    totalRecords,
    pageLimit,
    onPageChanged,
  });

  // useImperativeHandle(ref, () => {
  //   return {
  //     setTotalRecords: (total: React.SetStateAction<number>) => {
  //       setTotalRecords(total);
  //     },
  //   };
  // });

  return (
    <>
      {totalRecords > pageLimit && (
        <div className="w-full h-16 sticky bottom-0 left-0 right-0">
          <nav
            className="bg-white dark:bg-[#192130] px-4 py-3 flex items-center justify-between border-t border-gray-200 dark:border-gray-800 sm:px-6"
            aria-label="Pagination"
          >
            <div className="hidden sm:block">
              <p className="text-sm text-gray-700 dark:text-gray-200">
                Showing{" "}
                <span className="font-medium">
                  {(parseInt(currentPage) - 1) * pageLimit + 1}
                </span>{" "}
                to{" "}
                {currentPage * pageLimit <= totalRecords && (
                  <span className="font-medium">{currentPage * pageLimit}</span>
                )}
                {currentPage * pageLimit > totalRecords && (
                  <span className="font-medium">{totalRecords}</span>
                )}{" "}
                of <span className="font-medium">{totalRecords}</span> results
              </p>
              {/* Current: {currentPage} */}
            </div>
            <div className="flex-1 flex justify-between sm:justify-end">
              <PlainButton
                onClick={() => {
                  gotoPage(currentPage - 1);
                }}
                disable={currentPage === 1}
              >
                Previous
              </PlainButton>
              <PlainButton
                additionalClasses="ml-3"
                onClick={() => {
                  gotoPage(currentPage + 1);
                }}
              >
                Next
              </PlainButton>
            </div>
          </nav>
        </div>
      )}
    </>
  );
});

export default PrevNextFullPaginator;
