import { useState, useEffect } from "react";
import React from "react";
import RadioBase from "./base";
import TextInput from "../../react-text-inputs";
import { CheckIcon, XIcon } from "@heroicons/react/solid";

export default function Default(props) {
  return (
    <RadioBase
      OptionsRenderer={OptionsRenderer}
      optionsWrapperClasses="space-y-4"
      {...props}
      multiple={false}
    />
  );
}

const OptionsRenderer = ({ value, selected, props }) => {
  const [found, setFound] = useState(false);

  useEffect(() => {
    let localFound = selected.some((current) => current.value === value.value);
    setFound(localFound === false ? false : true);
  }, [selected]);

  return (
    <div className="flex items-center">
      <input
        id={value.value}
        name={props.name}
        type="radio"
        className="h-4 w-4 text-gray-600 border-gray-300 dark:border-gray-600  bg-white dark:bg-gray-800 focus:ring-0 focus:ring-offset-0 checked:bg-blue-500"
        checked={found === true}
        onChange={() => {}}
      />
      <label
        htmlFor={value.value}
        className="ml-3 block text-sm font-medium  text-gray-700 dark:text-gray-200"
      >
        {value.label}
      </label>
    </div>
  );
};
