import React, { useEffect } from "react";
import useAuth from "./useAuth";
import SessionMessageWrapper from "../utils/SessionMessageWrapper";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { BaseLoader } from "../reusejs";

const AuthWrapper = function (props: { children: any }) {
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (user) {
      let email = user.attributes.find(a => a.type === "email");
      if (email !== undefined) {
        if (window?.Beacon) {
          window.Beacon('identify', {
            email: email.value,
          })
        }
      }

    }
  }, [user])

  const { token, authCheckComplete } = useAuth(() => {
    // router.push('/logout');
  }, process.env.NEXT_PUBLIC_LOGIN_URL);

  if (
    !authCheckComplete ||
    !token ||
    token?.isLoggedIn === false ||
    user === null
  ) {
    return (
      <main className="justify-center max-w-7xl mx-auto flex flex-col items-center min-h-screen">
        <BaseLoader message="Loading..." />
      </main>
    );
  }

  return <SessionMessageWrapper>{props.children}</SessionMessageWrapper>;
};

export default AuthWrapper;
