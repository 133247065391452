import React, { useRef, useEffect } from "react";
import { RootState } from "store";
// import {
//   timeAgo as timeAgoHelper,
//   getFormattedDate as getFormattedDateHelper,
//   getFormattedTimestamp as getFormattedTimestampHelper,
//   timeAgoFromTimestamp as timeAgoFromTimestampHelper,
// } from "./dateHelpers";
import { useSelector, shallowEqual, TypedUseSelectorHook } from "react-redux";
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
import { DateTime } from "luxon";

const convertGMTDateToTimezone = (
  date,
  userTimezone,
  format = "MMM dd, yyyy, hh:mm a"
) => {
  return DateTime.fromISO(date, { zone: userTimezone }).toFormat(format);
};

const distanceGMTDateForHumans = (gmtDate, timezone, style = "narrow") => {
  return DateTime.fromISO(gmtDate, { zone: timezone }).toRelative({
    round: true,
    style: style,
  });
};

function useDateHelpers() {
  const { user } = useAppSelector(
    ({ team, auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  const userTimezone = user?.profile?.timezone || "GMT";

  const getCurrentTime = (days = 0) => {
    return DateTime.fromISO(new Date().toISOString(), {
      zone: userTimezone,
    }).plus({ days });
  };

  const startAndEnds = (isoDate) => {
    const start = DateTime.fromISO(isoDate, {
      zone: userTimezone,
    })
      .startOf("day")
      .toISO();

    const end = DateTime.fromISO(isoDate, {
      zone: userTimezone,
    })
      .endOf("day")
      .toISO();

    return { start, end };
  };

  const dateTimeInUTC = (isoDate) => {
    return DateTime.fromISO(isoDate).toUTC().toISO();
  };

  const timeAgo = (date, timestamp = false, style = "narrow") => {
    if (timestamp) {
      date = new Date(date * 1000).toISOString();
    }
    return distanceGMTDateForHumans(date, userTimezone, style);
  };

  const getFormattedDate = (
    date,
    timestamp = false,
    format = "MMM dd, yyyy, hh:mm a"
  ) => {
    if (timestamp) {
      date = new Date(date * 1000).toISOString();
    }

    return convertGMTDateToTimezone(date, userTimezone, format);
  };

  return {
    timeAgo,
    getFormattedDate,
    getCurrentTime,
    startAndEnds,
    dateTimeInUTC,
    user,
  };
}

export { useDateHelpers, convertGMTDateToTimezone, distanceGMTDateForHumans };
