import { axiosObject } from "../setup";

function teamsUserCanViewTeams(tenant) {
  return new Promise((resolve, reject) => {
    let uri = "/access/teams";

    axiosObject
      .get(uri, {
        params: {
          tenant,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function teamsUserCanViewTeamMembers(uuid) {
  return new Promise((resolve, reject) => {
    let uri = `/access/teams/${uuid}/members`;

    axiosObject
      .get(uri, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        console.log("errors", errors.response.data.errors);
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function teamsUserCanViewTeam(id) {
  return new Promise((resolve, reject) => {
    let uri = `/access/teams/${id}`;

    axiosObject
      .get(uri)
      .then((response) => {
        // console.log('Called api to fetch teams', response.data);
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function teamsUserCanViewInvites() {
  return new Promise((resolve, reject) => {
    let uri = "/access/teams/invites";

    axiosObject
      .get(uri, {
        params: {},
      })
      .then((response) => {
        console.log("Called api to fetch teams", response.data);
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function teamsUserCanUpdateTeam(uuid, payload) {
  return new Promise((resolve, reject) => {
    let uri = `/access/teams/${uuid}`;

    axiosObject
      .put(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function teamsUserCanDeleteTeamMember(uuid, memberUuid) {
  return new Promise((resolve, reject) => {
    let uri = `/access/teams/${uuid}/members/${memberUuid}`;

    axiosObject
      .delete(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        console.log("errors", errors.response.data.errors);
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function teamsUserCanCreateTeamMember(uuid, payload) {
  return new Promise((resolve, reject) => {
    let uri = `/access/teams/${uuid}/members`;

    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        console.log("errors", errors.response.data.errors);
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function teamsUserCanCreateTeam(payload) {
  return new Promise((resolve, reject) => {
    let uri = "/access/teams";

    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        console.log("errors", errors.response.data.errors);
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function teamsUserCanAcceptTeamMembership(team_uuid, member_uuid) {
  return new Promise((resolve, reject) => {
    let uri = `/access/teams/${team_uuid}/members/${member_uuid}/accept`;

    axiosObject
      .post(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function adminCanEditTeamMemberPermissions(team_uuid, member_uuid, payload) {
  return new Promise((resolve, reject) => {
    let uri = `/access/teams/${team_uuid}/members/${member_uuid}/assign-role-permission`;

    axiosObject
      .put(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

export {
  teamsUserCanViewTeams,
  teamsUserCanCreateTeam,
  teamsUserCanDeleteTeamMember,
  teamsUserCanViewTeam,
  teamsUserCanViewTeamMembers,
  teamsUserCanCreateTeamMember,
  teamsUserCanAcceptTeamMembership,
  teamsUserCanViewInvites,
  adminCanEditTeamMemberPermissions,
};
