import { axiosObject } from "../setup";

function reportsCanViewEventsHistory(environment, params) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/reports/${environment}/events`;

    axiosObject
      .get(uri, { params: params })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function reportsCanViewEventHistory(environment, uuid) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/reports/${environment}/events/${uuid}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function reportsCanViewDeliveriesHistory(environment, params) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/reports/${environment}/deliveries`;

    axiosObject
      .get(uri, { params: params })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function reportsCanViewDeliveryHistory(environment, uuid) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/reports/${environment}/deliveries/${uuid}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function reportsCanViewDeadEvents(params) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/reports/graveyard`;

    axiosObject
      .get(uri, { params: params })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function reportsCanViewDeadEvent(uuid) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/reports/graveyard/${uuid}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

export {
  reportsCanViewEventsHistory,
  reportsCanViewEventHistory,
  reportsCanViewDeliveriesHistory,
  reportsCanViewDeliveryHistory,
  reportsCanViewDeadEvents,
  reportsCanViewDeadEvent,
};
