import React, { useEffect } from "react";
import classNames from "../react-utils/classNames";
import { useState } from "react";
import { Switch } from "@headlessui/react";
import useMounted from "../react-utils/useMounted"
import useDidMountEffect from "../react-utils/useDidMountEffect"

const Base = ({
  label = null,
  description = null,
  defaultValue = false,
  onChange = () => { },
  ...props
}) => {
  const [enabled, setEnabled] = useState(defaultValue);

  // useEffect(() => {
  //   onChange(enabled);
  // }, [enabled]);


  useDidMountEffect(() => {
    onChange(enabled);
  }, [enabled]);

  useEffect(() => {
    setEnabled(defaultValue);
  }, [defaultValue]);

  return (
    <Switch.Group
      as="div"
      className={classNames(
        "flex items-center",
        props.reverse ? " flex-row-reverse" : ""
      )}
    >
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={classNames(
          enabled
            ? props.enabledClasses || "bg-blue-600"
            : props.disabledClasses || "bg-gray-200 dark:bg-gray-700",
          props.focusRingClasses || "focus:ring-blue-500",
          props.focusClasses ||
          "focus:outline-none focus:ring-2 focus:ring-offset-2",
          props.sizeClasses || "h-6 w-11",
          props.borderClasses || "border-2 border-transparent",
          "relative inline-flex flex-shrink-0 rounded-full cursor-pointer transition-colors ease-in-out duration-200"
        )}
      >
        <span className="sr-only">Enable notifications</span>
        <span
          className={classNames(
            enabled ? "translate-x-5" : "translate-x-0",
            props.circleSizeClasses || "h-5 w-5",
            props.circleColorClasses || "bg-white dark:bg-gray-900",
            "pointer-events-none inline-block rounded-full shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
      <span className="flex-grow flex flex-col">
        {label && (
          <Switch.Label
            as="span"
            className={classNames(
              props.labelClasses || "ml-3 text-gray-900 dark:text-white"
            )}
            passive={props.passive}
          >
            {label}
          </Switch.Label>
        )}
        {description && (
          <Switch.Description
            as="span"
            className={classNames(
              props.descriptionClasses ||
              "text-sm text-gray-500 dark:text-gray-400"
            )}
          >
            {description}
          </Switch.Description>
        )}
      </span>
    </Switch.Group>
  );
};

export default Base;
