import { axiosObject } from "../setup";
import { webStore, clearAuth, clearTeamSlice } from "store";
import * as axios from "axios";
var edgeAxios = axios.create();

edgeAxios.defaults.headers.common = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

function ingestEvent(identifier, payload) {
  payload['api_token'] = webStore.getState().auth.accessToken;
  payload['x_team_identifier'] = webStore.getState().team.currentTeam["teams*uuid"];

  return new Promise((resolve, reject) => {
    let uri = `/neptune/${identifier}/events`;

    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });

}

function eventsViewEvents(params) {
  return new Promise((resolve, reject) => {
    let uri = "/neptune/events";

    axiosObject
      .get(uri, { params: params })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function eventsCreateEvent(payload) {
  return new Promise((resolve, reject) => {
    let uri = "/neptune/events";

    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function eventsUpdateEvent(uuid, payload) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/events/${uuid}`;

    axiosObject
      .put(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function eventsViewEvent(uuid) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/events/${uuid}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function eventsPostEvent(identifier, payload) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/${identifier}/events`;

    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function notificationsCreateNotification(payload) {
  return new Promise((resolve, reject) => {
    let uri = "/neptune/notifications";

    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function notificationsDeleteNotification(uuid) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/notifications/${uuid}`;

    axiosObject
      .delete(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function notificationsViewNotification(uuid) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/notifications/${uuid}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function notificationsViewNotifications(params) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/notifications`;

    axiosObject
      .get(uri, { params: params })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

export {
  eventsViewEvents,
  eventsPostEvent,
  eventsViewEvent,
  eventsCreateEvent,
  eventsUpdateEvent,
  notificationsCreateNotification,
  notificationsDeleteNotification,
  notificationsViewNotification,
  notificationsViewNotifications,
  ingestEvent
};
