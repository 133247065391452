import { axiosObject } from "../setup";

function settingsSaveDevEnvironment(payload) {
    return new Promise((resolve, reject) => {
        let uri = `/neptune/settings/save-dev-environment`;

        // {
        //     "environment_uuid": "c3e4a7e2-a90d-4e24-bef3-48f093a798ab"
        // }

        axiosObject
            .post(uri, payload)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errors) => {
                if (errors.response.status === 422) {
                    reject(errors.response.data.errors);
                }
                reject(errors);
            });
    });
}

function settingsGetTeamSettings() {
    return new Promise((resolve, reject) => {
        let uri = `/neptune/settings`;

        axiosObject
            .get(uri)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errors) => {
                if (errors.response.status === 422) {
                    reject(errors.response.data.errors);
                }
                reject(errors);
            });
    });
}

export { settingsSaveDevEnvironment, settingsGetTeamSettings };
