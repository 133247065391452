import { axiosObject } from "../setup";



function templatesUpsertTemplates(payload) {
  return new Promise((resolve, reject) => {
    let uri = "/neptune/templates/upsert";

    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function templatesUserCanCreateTemplate(payload) {
  return new Promise((resolve, reject) => {
    let uri = "/neptune/templates";

    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function templatesUserCanDeleteTemplate(id) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/templates/${id}`;

    axiosObject
      .delete(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function templatesUserCanUpdateTemplate(payload, id) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/templates/${id}`;

    axiosObject
      .put(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function templatesUserCanViewSingleTemplate(id) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/templates/${id}`;

    axiosObject
      .get(uri)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

function templatesViewTemplates(params) {
  return new Promise((resolve, reject) => {
    let uri = "/neptune/templates";

    axiosObject
      .get(uri, { params: params })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

export {
  templatesUserCanCreateTemplate,
  templatesUserCanDeleteTemplate,
  templatesUserCanUpdateTemplate,
  templatesUserCanViewSingleTemplate,
  templatesViewTemplates,
  templatesUpsertTemplates
};
