import React, { forwardRef } from "react";
import TextFilter from "./TextFilter";
import CheckboxFilter from "./CheckboxFilter";
import RadioFilter from "./RadioFilter";

const Filter = forwardRef(function Filter({ item, applyFilter }, ref) {
  const filterable = item.filterable;
  return (
    <td className="px-6 py-3">
      {filterable && filterable.type === "text" && (
        <TextFilter
          onChange={(e) => {
            applyFilter(e);
          }}
        />
      )}

      {filterable && filterable.type === "checkbox" && (
        <CheckboxFilter
          selected={filterable.selected}
          options={filterable.options}
          onChange={(e) => {
            applyFilter(e);
          }}
        />
      )}

      {filterable && filterable.type === "radio" && (
        <RadioFilter
          selected={filterable.selected}
          options={filterable.options}
          onChange={(e) => {
            console.log("Radio filter changed", e);
            applyFilter(e);
          }}
        />
      )}

      {filterable && filterable.type === "clear" && (
        <div className="pr-6 text-right">
          <span
            className="text-xs text-right text-gray-500 capitalize cursor-pointer hover:text-gray-700"
            onClick={() => {
              applyFilter("clear");
            }}
          >
            Clear
          </span>
        </div>
      )}
    </td>
  );
});

Filter.defaultProps = {};

export default Filter;
