import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
} from "react";
import useDataTable from "../useDataTable";
import FirstLastPaginator from "../FirstLastPaginator";
import classNames from "../../react-utils/classNames";
import { themeScheme } from "utils";

const StackColumn = React.forwardRef(function StackColumn(
  { data = [], ItemRenderer = () => {}, height = "500px", ...props },
  ref
) {
  const stackRef = useRef(null);

  return (
    <>
      {/* {`${data.length * 100}px !important`} */}
      <nav
        ref={stackRef}
        aria-label="Message list"
        className="flex-1 overflow-y-auto"
        style={{
          height: height,
        }}
      >
        <ul
          role="list"
          className={classNames(
            themeScheme.bg,
            "border-0 border-b divide-y divide-gray-200 dark:divide-gray-800"
          )}
        >
          {data.length ? (
            data.map((row, i) => (
              <li key={`stack-column-${i}`}>
                <ItemRenderer item={row} index={i} />
              </li>
            ))
          ) : (
            <></>
          )}
        </ul>
      </nav>
    </>
  );
});

export default StackColumn;
