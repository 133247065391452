import React, {
  Fragment,
  useState,
  useEffect,
  useImperativeHandle,
} from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  ArchiveIcon as ArchiveIconSolid,
  ChevronDownIcon,
  ChevronUpIcon,
  DotsVerticalIcon,
  FolderDownloadIcon,
  PencilIcon,
  ReplyIcon,
  SearchIcon,
  UserAddIcon,
} from "@heroicons/react/solid";

import useDataSource from "./useDataSource";

const message = {
  subject: "Re: New pricing for existing customers",
  sender: "joearmstrong@example.com",
  status: "Open",
  items: [
    {
      id: 1,
      author: "Joe Armstrong",
      date: "Yesterday at 7:24am",
      datetime: "2021-01-28T19:24",
      body: "<p>Thanks so much! Can't wait to try it out.</p>",
    },
    {
      id: 2,
      author: "Monica White",
      date: "Wednesday at 4:35pm",
      datetime: "2021-01-27T16:35",
      body: `
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada at ultricies tincidunt elit et, enim. Habitant nunc, adipiscing non fermentum, sed est a, aliquet. Lorem in vel libero vel augue aliquet dui commodo.</p>
          <p>Nec malesuada sed sit ut aliquet. Cras ac pharetra, sapien purus vitae vestibulum auctor faucibus ullamcorper. Leo quam tincidunt porttitor neque, velit sed. Tortor mauris ornare ut tellus sed aliquet amet venenatis condimentum. Convallis accumsan et nunc eleifend.</p>
          <p><strong style="font-weight: 600;">Monica White</strong><br/>Customer Service</p>
        `,
    },
    {
      id: 3,
      author: "Joe Armstrong",
      date: "Wednesday at 4:09pm",
      datetime: "2021-01-27T16:09",
      body: `
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada at ultricies tincidunt elit et, enim. Habitant nunc, adipiscing non fermentum, sed est a, aliquet. Lorem in vel libero vel augue aliquet dui commodo.</p>
          <p>Nec malesuada sed sit ut aliquet. Cras ac pharetra, sapien purus vitae vestibulum auctor faucibus ullamcorper. Leo quam tincidunt porttitor neque, velit sed. Tortor mauris ornare ut tellus sed aliquet amet venenatis condimentum. Convallis accumsan et nunc eleifend.</p>
          <p>– Joe</p>
        `,
    },
  ],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SingleDataColumn = React.forwardRef(function SingleDataColumn(
  {
    dataSource,
    sortBy = "default",
    sortOrder = "asc",
    perPage = 10,
    params = {},
    ...props
  },
  ref
) {
  const {
    orderBy,
    setOrderBy,
    loading,
    data,
    setRefresh,
    setCurrentPage,
    totalRecords,
    filters,
    applyFilter,
    query,
    setQuery,
  } = useDataSource({
    sortOrder,
    sortBy,
    dataSource,
    perPage,
    params,
  });

  useImperativeHandle(ref, () => {
    return {
      refresh: () => {
        setRefresh(true);
      },
    };
  });

  return (
    // <div className="h-screen flex flex-col">
    //   <div className="min-h-0 flex-1 flex overflow-hidden">
    <main className="min-w-0 flex-1 xl:flex">
      <section
        aria-labelledby="message-heading"
        className="min-w-0 flex-1 h-full flex flex-col overflow-hidden xl:order-last"
      >
        <Detail />
      </section>

      <aside className="hidden xl:block xl:flex-shrink-0 xl:order-first">
        <Data items={data} query={query} setQuery={setQuery} />
      </aside>
    </main>
    //   </div>
    // </div>
  );
});

const Data = ({ items, query, setQuery }) => {
  const [queryText, setQueryText] = useState(query);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setQuery(queryText);
    }, 200);
    return () => clearTimeout(debounceTimer);
  }, [queryText]);

  return (
    <div className="h-full relative flex flex-col w-96 border-r border-gray-200 dark:border-gray-800 bg-gray-100 dark:bg-gray-900">
      <div className="flex-shrink-0">
        <div className="h-16 bg-white dark:bg-gray-900 px-6 flex flex-col justify-center">
          <div className="flex items-baseline space-x-3">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white">
              Inbox
            </h2>
            <p className="text-sm font-medium text-gray-500 dark:text-gray-300">
              10 messages
            </p>
          </div>
        </div>

        <div>
          <input
            type="text"
            value={queryText}
            onChange={(e) => {
              setQueryText(e.target.value);
            }}
            placeholder="Type to Filter.."
            className="border-gray-200 dark:border-gray-800 px-6 py-3 focus:ring-gray-200 focus:border-gray-200 dark:focus:ring-gray-800 dark:focus:border-gray-800 block w-full sm:text-sm text-xs border-1 bg-white dark:bg-gray-900"
          />
        </div>
      </div>

      <nav
        aria-label="Message list"
        className="max-h-screen flex-1 overflow-y-scroll"
      >
        <ul
          role="list"
          className="border-b border-gray-200 dark:border-gray-800 divide-y divide-gray-200 dark:divide-gray-800"
        >
          {items.map((message) => (
            <li
              key={message.id}
              className="relative bg-white dark:bg-gray-900 py-5 px-6 hover:bg-gray-50 dark:hover:bg-gray-800 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600"
            >
              <div className="flex justify-between space-x-3">
                <div className="min-w-0 flex-1">
                  <a href={message.href} className="block focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
                      {message.sender}
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-300 truncate">
                      {message.subject}
                    </p>
                  </a>
                </div>
                <time
                  dateTime={message.datetime}
                  className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
                >
                  {message.date}
                </time>
              </div>
              <div className="mt-1">
                <p className="line-clamp-2 text-sm text-gray-600 dark:text-gray-400">
                  {message.preview}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </nav>

      <div className="w-full h-10 sticky bottom-0 left-0 right-0">
        <div className="border-t border-b border-gray-200 dark:border-gray-800 bg-gray-50 dark:bg-[#192130] px-6 py-2 text-sm font-medium text-gray-500 dark:text-gray-300">
          Pagination
        </div>
      </div>
    </div>
  );
};

const Detail = () => {
  return (
    <>
      <div className="flex-shrink-0 bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-800">
        {/* Toolbar*/}
        <div className="h-16 flex flex-col justify-center">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="py-3 flex justify-between">
              {/* Left buttons */}
              <div>
                <span className="relative z-0 inline-flex shadow-sm rounded-md sm:shadow-none sm:space-x-3">
                  <span className="inline-flex sm:shadow-sm">
                    <button
                      type="button"
                      className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600 dark:bg-gray-900 dark:text-white dark:border-gray-700"
                    >
                      <ReplyIcon
                        className="mr-2.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Reply</span>
                    </button>
                    <button
                      type="button"
                      className="hidden sm:inline-flex -ml-px relative items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600 dark:bg-gray-900 dark:text-white dark:border-gray-700"
                    >
                      <PencilIcon
                        className="mr-2.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Note</span>
                    </button>
                    <button
                      type="button"
                      className="hidden sm:inline-flex -ml-px relative items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600 dark:bg-gray-900 dark:text-white dark:border-gray-700"
                    >
                      <UserAddIcon
                        className="mr-2.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Assign</span>
                    </button>
                  </span>

                  <span className="hidden lg:flex space-x-3">
                    <button
                      type="button"
                      className="hidden sm:inline-flex -ml-px relative items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600 dark:bg-gray-900 dark:text-white dark:border-gray-700"
                    >
                      <ArchiveIconSolid
                        className="mr-2.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Archive</span>
                    </button>
                    <button
                      type="button"
                      className="hidden sm:inline-flex -ml-px relative items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600 dark:bg-gray-900 dark:text-white dark:border-gray-700"
                    >
                      <FolderDownloadIcon
                        className="mr-2.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Move</span>
                    </button>
                  </span>

                  <Menu
                    as="span"
                    className="-ml-px relative block sm:shadow-sm lg:hidden"
                  >
                    <div>
                      <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600 sm:rounded-md sm:px-3 dark:bg-gray-900 dark:text-white dark:border-gray-700">
                        <span className="sr-only sm:hidden">More</span>
                        <span className="hidden sm:inline">More</span>
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400 sm:ml-2 sm:-mr-1"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block sm:hidden px-4 py-2 text-sm"
                                )}
                              >
                                Note
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block sm:hidden px-4 py-2 text-sm"
                                )}
                              >
                                Assign
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Archive
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Move
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </span>
              </div>

              {/* Right buttons */}
              <nav aria-label="Pagination">
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                  <a
                    href="#"
                    className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600 dark:bg-gray-900 dark:text-white dark:border-gray-700"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  </a>
                  <a
                    href="#"
                    className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600 dark:bg-gray-900 dark:text-white dark:border-gray-700"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  </a>
                </span>
              </nav>
            </div>
          </div>
        </div>
        {/* Message header */}
      </div>

      <div className="min-h-0 flex-1 overflow-y-auto">
        <div className="bg-white dark:bg-gray-900 pt-5 pb-6 border-b border-gray-300 dark:border-gray-800">
          <div className="px-4 sm:flex sm:justify-between sm:items-baseline sm:px-6 lg:px-8">
            <div className="sm:w-0 sm:flex-1">
              <h1
                id="message-heading"
                className="text-lg font-medium text-gray-900 dark:text-white"
              >
                {message.subject}
              </h1>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-300 truncate">
                {message.sender}
              </p>
            </div>

            <div className="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:justify-start">
              <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-cyan-100 text-cyan-800">
                {message.status}
              </span>
              <Menu as="div" className="ml-3 relative inline-block text-left">
                <div>
                  <Menu.Button className="-my-2 p-2 rounded-full bg-white dark:bg-gray-900 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-600">
                    <span className="sr-only">Open options</span>
                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "w-full flex justify-between px-4 py-2 text-sm"
                            )}
                          >
                            <span>Copy email address</span>
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "flex justify-between px-4 py-2 text-sm"
                            )}
                          >
                            <span>Previous conversations</span>
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "flex justify-between px-4 py-2 text-sm"
                            )}
                          >
                            <span>View original</span>
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        {/* Thread section*/}
        <ul role="list" className="py-4 space-y-2 sm:px-6 sm:space-y-4 lg:px-8">
          {message.items.map((item) => (
            <li
              key={item.id}
              className="bg-white dark:bg-gray-900 px-4 py-6 border border-gray-300 dark:border-gray-800 sm:rounded-lg sm:px-6"
            >
              <div className="sm:flex sm:justify-between sm:items-baseline">
                <h3 className="text-base font-medium">
                  <span className="text-gray-900 dark:text-white">
                    {item.author}
                  </span>{" "}
                  <span className="text-gray-600 dark:text-gray-300">
                    wrote
                  </span>
                </h3>
                <p className="mt-1 text-sm text-gray-600 whitespace-nowrap sm:mt-0 sm:ml-3">
                  <time dateTime={item.datetime}>{item.date}</time>
                </p>
              </div>
              <div
                className="mt-4 space-y-6 text-sm text-gray-800 dark:text-gray-100"
                dangerouslySetInnerHTML={{ __html: item.body }}
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SingleDataColumn;
