import { axiosObject } from "../setup";

function getUser() {
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        let result = await callBackend();
        resolve(result);
      } catch (err) {
        reject(err);
      }
    })();
  });
}

function callBackend() {
  return new Promise((resolve, reject) => {
    let uri = "/access/user";

    axiosObject
      .get(uri)
      .then((response) => {
        // console.log('r', response.data);
        resolve(response.data);
      })
      .catch((errors) => {
        // console.log('errr', errors);
        reject(errors);
      });
  });
}

export { getUser };
