import React, { useEffect, useState, useRef } from "react";
import classNames from "../../react-utils/classNames";
import { themeScheme } from "utils";
import { Fragment } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

export default function DropdownMenu({
  item,
  LinkRenderer = (props: any) => {
    let { href, children, item, ...rest } = props;
    return <a href={href}>{children}</a>;
  },
  onButtonPress = () => {},
  ChevronIconComponent = ChevronDownIcon,
  ...props
}) {
  // const LinkRenderer = props.LinkRenderer;

  return (
    <Menu as="div" key={item.key} className="relative text-right">
      <Menu.Button
        className={`flex items-center text-sm font-medium ${themeScheme.text} rounded-md focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-gray-50 dark:focus:ring-gray-800`}
      >
        {!props.labelComponent && (
          <span
            onClick={() => {
              onButtonPress(item);
            }}
            className={classNames(
              "relative py-2 px-0 flex items-center initial:border rounded-md initial:border-gray-100 initial:dark:border-gray-700",
              item.labelClasses
            )}
          >
            {item.helper !== "" && (
              <span className="rotate-[270deg] leading-none font-thin text-xs lowercase text-gray-400 dark:text-gray-400">
                {item.helper}
              </span>
            )}
            <span className="px-4 flex items-center">
              <span>{item.name}</span>
              <ChevronIconComponent
                className="ml-1 h-4 w-4 text-gray-500 dark:text-gray-300"
                aria-hidden="true"
              />
            </span>
          </span>
        )}

        {props.labelComponent && <props.labelComponent />}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`${props.openTo} absolute z-30  mt-4 w-40 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none border dark:bg-gray-800 ${themeScheme.bg} ${themeScheme.border} `}
        >
          <div className="py-1">
            {item.children.map((child) => {
              if (!child.isDisabled) {
                return (
                  <Menu.Item key={child.key}>
                    {({ active }) => (
                      <LinkRenderer
                        href={child.href}
                        className={classNames(
                          active ? "bg-gray-100 dark:hover:bg-gray-700" : "",
                          "text-left block px-4 py-2 text-sm font-normal text-gray-700 dark:text-white"
                        )}
                        item={item}
                        child={child}
                      >
                        {child.name}
                      </LinkRenderer>
                    )}
                  </Menu.Item>
                );
              }

              if (child.isDisabled) {
                return (
                  <Menu.Item key={child.key} disabled>
                    {({ active }) => (
                      <span
                        className={classNames(
                          themeScheme.border,
                          child.spanClasses ||
                            `text-left block px-4 py-2 text-xs font-normal text-gray-400 dark:text-gray-400 border-0 border-b`
                        )}
                      >
                        {child.name}
                      </span>
                    )}
                  </Menu.Item>
                );
              }
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
