import { axiosObject } from "../setup";
import { logger, baseValidator } from "utils";

/**
 *  
 * mode: 'email',
    password: 'Kissmiss9!',
    confirm_password: 'Kissmiss9!',
    email: 'rajiv@betalectic.com',
    username: '',
    mobile_code: '91',
    mobile_number: '',
 */

const emailConstraints = {
  value: {
    presence: {
      allowEmpty: false,
      message: "^Please enter your Email ID",
    },
    email: {
      message: "^Please enter a valid Email ID",
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: "^Please enter your Password",
    },
  },
};

function login(payload) {
  return new Promise((resolve, reject) => {
    var jsValidationErrors;
    var apiPayload = {};

    if (payload["type"] === "email") {
      jsValidationErrors = baseValidator(payload, emailConstraints);
      apiPayload = {
        type: "email",
        value: payload["value"],
        password: payload["password"],
      };
    }

    if (jsValidationErrors === true) {
      let uri = "/access/login";

      logger("Call Login", apiPayload, uri);

      axiosObject
        .post(uri, apiPayload, { withCredentials: true })
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          logger("/access/login catch errors", errors.response.data);

          if (
            errors &&
            errors?.response &&
            errors.response?.status &&
            errors.response.status === 422 &&
            errors.response.data.message === "Invalid Username or Password"
          ) {
            reject({
              value: "Invalid Username or Password",
              password: "Invalid Username or Password",
            });
          }

          if (
            errors.response.status === 422 &&
            errors.response.data.message === "AttributeNotVerified"
          ) {
            reject({
              AttributeNotVerified: "AttributeNotVerified",
            });
          }
          if (errors.response.status === 422) {
            reject(errors.response.data.errors);
          }
          if (errors.response.status === 401) {
            reject({
              password: "Incorrect Username or Password",
            });
          }
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

function usersLoginWithGoogle(code, redirect_to) {
  return new Promise((resolve, reject) => {
    // console.log("usersLoginWithGoogle-called");
    let uri = `/access/login/google`;

    // console.log("payload-3214", code, redirect_to);

    axiosObject
      .get(uri, {
        params: {
          code,
          redirect_to,
        },
      })
      .then((response) => {
        // console.log("usersLoginWithGoogle-response", response.data);
        resolve(response.data);
      })
      .catch((errors) => {
        // console.log("usersLoginWithGoogle-errors", errors);
        reject(errors);
      });
  });
}

export { login, usersLoginWithGoogle };
