import cookie from "cookie";
import { clearAppLocalStorageOnLogout } from "./neptuneLocalStorageCleaner";

const setAccessToken = (access_token) => {
  // console.log("setAccessToken-called", access_token);
  // console.log("process.env.NODE_ENV-123", process.env.NODE_ENV);
  // console.log("process.env.COOKIE_NAME-123", process.env.COOKIE_NAME);
  return cookie.serialize("access_token", access_token, {
    httpOnly: true,
    secure: process.env.NODE_ENV !== "development",
    maxAge: 60 * 60 * 24 * 365,
    sameSite: process.env.NODE_ENV !== "development" ? "None" : "strict",
    domain: process.env.COOKIE_NAME,
    path: "/",
  });
};

const removeAccessToken = (req) => {
  try {
    /* we can clear local storage here */
    console.log("removeAccessToken-called");

    // clearAppLocalStorageOnLogout();

    return cookie.serialize("access_token", "", {
      httpOnly: true,
      secure: process.env.NODE_ENV !== "development",
      maxAge: -1,
      sameSite: process.env.NODE_ENV !== "development" ? "None" : "strict",
      domain: process.env.COOKIE_NAME,
      path: "/",
      // expires: new Date().toUTCString(),
    });
  } catch (error) {
    console.log("removeAccessToken-error", error);
  }
};

export { setAccessToken, removeAccessToken };
