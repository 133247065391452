import { login, usersLoginWithGoogle } from "../auth/login";
import { setAccessToken } from "./cookieHelper";
import { withIronSessionApiRoute } from "iron-session/next";
import { sessionOptions } from "./session";

const nextLogin = withIronSessionApiRoute(async (req, res) => {
  if (req.method === "POST") {
    const { type, value, password, mode, code, redirect_to } = req.body;
    let response = null;
    try {
      if (mode === "google") {
        response = await usersLoginWithGoogle(code, redirect_to);
      } else {
        response = await login({
          type,
          value,
          password,
        });
      }

      res.setHeader("Set-Cookie", [setAccessToken(response["access_token"])]);
      req.session.token = response;
      await req.session.save();
      res.status(200).json(response);
    } catch (error) {
      return res.status(422).json(error);
    }
  }
}, sessionOptions);

const nextGoogleLogin = withIronSessionApiRoute(async (req, res) => {
  if (req.method === "POST") {
    const { code, redirect_to } = req.body;
    try {
      let response = await usersLoginWithGoogle(code, redirect_to);

      res.setHeader("Set-Cookie", [setAccessToken(response["access_token"])]);
      req.session.token = response;
      await req.session.save();
      res.status(200).json(response);
    } catch (error) {
      console.log("error", error);
      return res.status(422).json(error);
    }
  }
}, sessionOptions);

export { nextLogin, nextGoogleLogin };
