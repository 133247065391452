import { clearTeamSlice, webStore } from "store";

const clearAppLocalStorageOnLogout = () => {
  try {
    console.log("logoutHelperCalled");
    // we are not clearing auth for now as per our discussion as the user is replaced when we call setState.
    // If needed we can uncomment it and clear auth also
    // webStore.dispatch(clearAuth());
    webStore.dispatch(clearTeamSlice());
  } catch (error) {
    throw error;
  }
};

export { clearAppLocalStorageOnLogout };
