const TwoColumn = ({ data = [], mode = "horizontal", ...props }) => {

    if (mode === 'horizontal') {
        return (
            <div className="max-w-5xl mt-6">
                <dl className="dark:sm:divide-gray-800 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                    {data.map((row, i) => (
                        <Row key={i} d={row} />
                    ))}
                </dl>
            </div>
        );
    }

    if (mode === 'vertical') {
        return (
            <dl className="sm:divide-y sm:divide-gray-200 dark:sm:divide-gray-800">
                {data.map((row, i) => (
                    <div className="py-4 sm:py-5" key={i} >
                        <Row d={row} />
                    </div>
                ))}
            </dl>
        );
    }

};

const Row = ({ d }) => {

    return (
        <div>
            <dt className="col-span-1 text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                {d.key}
            </dt>
            <dd className="col-span-3 mt-2 font-medium text-sm sm:col-span-2">
                {d.value}
            </dd>
        </div>
    )

}

export default TwoColumn;