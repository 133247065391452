import { axiosObject } from "../setup";

function analyticsCanViewEventsAnalytics(environment, payload) {
  return new Promise((resolve, reject) => {
    let uri = `/neptune/analytics/${environment}/events`;

    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        if (errors.response.status === 422) {
          reject(errors.response.data.errors);
        }
        reject(errors);
      });
  });
}

export { analyticsCanViewEventsAnalytics };
