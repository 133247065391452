import * as axios from "axios";
import { webStore, clearAuth, clearTeamSlice } from "store";
import { logger } from "utils";
// import { logger, deleteAllCookies } from "@frontend/utils";

var axiosObject = axios.create();

if (process.env.NEXT_PUBLIC_API_URL !== undefined) {
  axiosObject.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL;
}

axiosObject.defaults.headers.common = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

axiosObject.interceptors.request.use(
  async function (config) {
    logger(
      "Request:",
      `${config.method} - ${config.baseURL}${config.url}?${new URLSearchParams(
        config.params
      ).toString()}`
    );

    if (webStore.getState().auth.accessToken) {
      config.headers["Authorization"] = `Bearer ${
        webStore.getState().auth.accessToken
      }`;
    } else {
      // logger("Access token is not in local storage");
    }

    // console.log('webStore.getState()123', webStore.getState());

    // console.log(
    //   "webStore.getState().team.currentTeam",
    //   webStore.getState().team.currentTeam
    // );
    if (webStore.getState().team.currentTeam) {
      config.headers["X-TEAM-IDENTIFIER"] =
        webStore.getState().team.currentTeam["teams*uuid"];
    }

    return config;
  },
  function (error) {
    // logger("Request Interceptor Error", error);
    return Promise.reject(error);
  }
);

axiosObject.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // logger("Response interceptor error", error);
    if (
      error.response &&
      error.response.status &&
      error.response.status == 401
    ) {
      webStore.dispatch(clearAuth());
      webStore.dispatch(clearTeamSlice());
      // deleteAllCookies();
    }
    return Promise.reject(error);
  }
);

export { axiosObject };
